import React, { useCallback } from 'react';
import { DropdownEvent, DropdownInput, DropdownOption } from 'crazy-ui-next';
import { useController } from 'react-hook-form';

import { GamePlatform, GamePlatformTitleMap } from '@domain/enums/GamePlatform';
import { DefaultFormValue, DefaultFormValueLabels, FormComponent } from '@ui/hooks/form';
import { DirectConfigFiltersForm } from '@pages/directConfigList/filters/types/DirectConfigFiltersForm';
import { ReactComponent as BagSvg } from '@assets/svg/bag.svg';

const options: DropdownOption<GamePlatform | DefaultFormValue>[] = [
  { value: DefaultFormValue.ALL, label: DefaultFormValueLabels[DefaultFormValue.ALL] },
  { value: GamePlatform.IOS, label: GamePlatformTitleMap[GamePlatform.IOS] },
  { value: GamePlatform.ANDROID, label: GamePlatformTitleMap[GamePlatform.ANDROID] }
];

type Props = Pick<FormComponent<DirectConfigFiltersForm>, 'control'>;

export function PlatformInput({ control }: Props) {
  const { field } = useController({ control, name: 'gamePlatform' });

  const handleChange = useCallback(
    (e: DropdownEvent<GamePlatform | DefaultFormValue>) => {
      field.onChange(e.value);
    },
    [field]
  );

  return (
    <DropdownInput
      name={field.name}
      inputIcon={<BagSvg />}
      value={field.value}
      options={options}
      handleChange={handleChange}
      labelText="Store"
    />
  );
}
