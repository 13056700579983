import React, { useCallback } from 'react';
import { useSelector, useDispatch } from '@ui/hooks/redux';

import { directConfigListSelectors } from '@infrastructure/store/directConfigList/directConfigListSelectors';
import { fetchNextDirectConfigs } from '@infrastructure/store/directConfigList/directConfigListActions';
import { DirectConfigTable } from '@pages/directConfigList/table/DirectConfigTable';
import { InfiniteScrollAnchor } from '@components/layout/infiniteScroll/InfiniteScrollAnchor';

export function DirectConfigTableContainer() {
  const dispatch = useDispatch();

  const status = useSelector(directConfigListSelectors.getStatus);
  const directConfigs = useSelector(directConfigListSelectors.getDirectConfigs);
  const { isNextAvailable } = useSelector(directConfigListSelectors.getPagination);

  const handleFetchNext = useCallback(() => {
    dispatch(fetchNextDirectConfigs());
  }, [dispatch]);

  return (
    <>
      <DirectConfigTable status={status} list={directConfigs} />
      <InfiniteScrollAnchor isNextAvailable={isNextAvailable} status={status} handleFetchNext={handleFetchNext} />
    </>
  );
}
