import React from 'react';

import { DirectConfigState } from '@domain/enums/directConfig/DirectConfigState';
import { UserRoleType } from '@domain/enums/UserRoleType';
import { ReactComponent as CrossSvg } from '@assets/svg/cancel.svg';
import { ReactComponent as UndoSvg } from '@assets/svg/undo.svg';
import { ReactComponent as CopySvg } from '@assets/svg/copy.svg';
import { ReactComponent as PlaySvg } from '@assets/svg/play-rounded.svg';

import styles from '@pages/experimentDetails/components/actions/Actions.module.scss';

export enum ACTIONS {
  BACK = 'BACK',
  STOP = 'STOP',
  GO_LIVE = 'GO_LIVE',
  CLONE = 'CLONE'
}

export const ACTIONS_TITLE_MAP = {
  [ACTIONS.BACK]: 'Back',
  [ACTIONS.STOP]: 'Stop',
  [ACTIONS.CLONE]: 'Clone',
  [ACTIONS.GO_LIVE]: 'Go Live'
};

export const ACTIONS_ICON_MAP = {
  [ACTIONS.BACK]: <UndoSvg className={styles.icon} />,
  [ACTIONS.STOP]: <CrossSvg className={styles.highlightIcon} />,
  [ACTIONS.CLONE]: <CopySvg className={styles.icon} />,
  [ACTIONS.GO_LIVE]: <PlaySvg className={styles.icon} />
};

export function getActions(status: DirectConfigState) {
  switch (status) {
    case DirectConfigState.IN_DEV: {
      return [ACTIONS.GO_LIVE, ACTIONS.STOP, ACTIONS.CLONE, ACTIONS.BACK];
    }
    case DirectConfigState.LIVE: {
      return [ACTIONS.STOP, ACTIONS.CLONE, ACTIONS.BACK];
    }
    case DirectConfigState.CANCELED: {
      return [ACTIONS.CLONE, ACTIONS.BACK];
    }
    case DirectConfigState.STOPPED: {
      return [ACTIONS.CLONE, ACTIONS.BACK];
    }
    default: {
      return [ACTIONS.CLONE, ACTIONS.BACK];
    }
  }
}

export function getActionsByUserRole(status: DirectConfigState, userRole: UserRoleType | null) {
  const buttons = getActions(status);

  if (userRole === UserRoleType.GAME_OWNER) {
    return buttons.filter((action) => ![ACTIONS.GO_LIVE, ACTIONS.STOP].includes(action));
  }

  return buttons;
}
