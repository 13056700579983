import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushBasicInfoForm,
  pushClone
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { BasicInfoParams } from '@pages/createDirectConfig/basicInfo/types/BasicInfoParams';

const basicInfoInitial: BasicInfoParams = new BasicInfoParams();

export const basicInfoReducer = createReducer(basicInfoInitial, (qb) => {
  qb.addCase(pushClone.success, (state, { payload }) => {
    if (payload) {
      return payload.basicInfo;
    }
    return state;
  });
  qb.addCase(pushBasicInfoForm, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return new BasicInfoParams();
  });
});
