import React from 'react';

import { StepsContainer } from '@pages/createDirectConfig/steps/StepsContainer';
import { FormControls } from '@pages/createDirectConfig/formControls/FormControls';

import styles from '@pages/createDirectConfig/CreateDirectConfig.module.scss';

export function CreateDirectConfig() {
  return (
    <section className={styles.form}>
      <StepsContainer />
      <FormControls />
    </section>
  );
}
