import React from 'react';
import { Button } from 'crazy-ui-next';

import { Routing } from '@infrastructure/routing';
import { DirectConfigTableContainer } from '@pages/directConfigList/table/DirectConfigTableContainer';
import { DirectConfigFiltersContainer } from '@pages/directConfigList/filters/DirectConfigFiltersContainer';
import { PageTitle } from '@components/layout/PageTitle';
import CheckPermission from '@components/layout/CheckPermissions';
import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';

export function DirectConfigListPage() {
  return (
    <>
      <PageTitle label="Segment Targeting List">
        <CheckPermission admin analyst qa>
          <Button href={Routing.getCreateDirectConfig()} icon={<PlusSvg />}>
            Create Segment Targeting
          </Button>
        </CheckPermission>
      </PageTitle>
      <main className="container">
        <DirectConfigFiltersContainer />
        <DirectConfigTableContainer />
      </main>
    </>
  );
}
