import React from 'react';

import styles from '@pages/experimentDetails/atoms/labels/Labels.module.scss';

type Props = {
  label: string;
};

export function Type({ label }: Props) {
  return (
    <div className={styles.type}>
      <div className={styles.title}>Type:</div>
      <div className={styles.value}>{label}</div>
    </div>
  );
}
