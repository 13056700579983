export enum VersionOperator {
  EQUAL = 'EQUAL',
  GREATER = 'GREATER',
  GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
  LESS = 'LESS',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL'
}

export const VersionOperatorLabels: { [key in VersionOperator]: string } = {
  [VersionOperator.EQUAL]: '=',
  [VersionOperator.GREATER]: '>',
  [VersionOperator.GREATER_OR_EQUAL]: '>=',
  [VersionOperator.LESS]: '<',
  [VersionOperator.LESS_OR_EQUAL]: '<='
};
