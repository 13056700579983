import React from 'react';
import { FieldValues, Path, useWatch } from 'react-hook-form';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { ControlGroupForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/types/AdProfileObjectiveForm';
import { ControlGroup } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/controlGroup/ControlGroup';
import { useSelector } from '@ui/hooks/redux';
import { FormComponent } from '@ui/hooks/form';

type Props<T extends FieldValues> = Pick<FormComponent<T>, 'control'> & {
  defaultControlGroup: ControlGroupForm;
};

export function ControlGroupContainer<T extends FieldValues>({ control, defaultControlGroup }: Props<T>) {
  const params = useWatch({
    control,
    name: 'params' as Path<T>
  });

  const variables = useSelector(configSelectors.getVariableList);
  const filteredVariables = variables.filter(({ name }) => params.includes(name));

  return <ControlGroup control={control} variables={filteredVariables} />;
}
