import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';
import { UniqueId } from '@domain/types';
import { TargetConfigParams } from '@pages/createDirectConfig/targetConfig/types/TargetConfigParams';
import { BasicInfoParams } from '@pages/createDirectConfig/basicInfo/types/BasicInfoParams';
import { RegionDto } from '@domain/models/RegionDto';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { GameStatsType } from '@domain/enums/GameStatsType';
import { ControlGroupByCountry } from '@domain/models/ControlGroup';
import { GLDParamDto } from '@domain/enums/GLDParamDto';
import { AdProfileObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/types/AdProfileObjectiveForm';
import { GameConfigObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';
import { DirectConfigFormState } from '@infrastructure/store/createDirectConfig/createDirectConfigReducer';
import { OverlappedConfigDto } from '@domain/models/experiment/OverlappedConfigDto';
import { ILiveConfig } from '@domain/models/experiment/LiveConfig';

const namespace = 'createDirectConfig';

export const pushDirectConfigCreate = ActionFactory.createRequestRoutine('DIRECT_CONFIG_CREATE_PUSH', namespace);

// form management
export const pushNextStep = ActionFactory.createAction('NEXT_STEP_PUSH', namespace);
export const pushSetStep = ActionFactory.createAction<UniqueId>('SET_STEP_PUSH', namespace);

// basic info
export const pushBasicInfoForm = ActionFactory.createAction<BasicInfoParams>('BASIC_INFO_FORM_PUSH', namespace);
export const setGame = ActionFactory.createAction('SET_GAME', namespace);

// target config
export const fetchRegions = ActionFactory.createRequestRoutine<
  void,
  {
    defaultRegions: RegionDto[];
    recommendedRegions: RegionDto[];
  }
>('REGIONS_FETCH', namespace);
export const fetchGameStats = ActionFactory.createRequestRoutine<void, GameStatsDto[]>('GAME_STATS_FETCH', namespace);
export const setGameStatsFilter = ActionFactory.createAction<GameStatsType>('GAME_STATS_FILTER_SET', namespace);

export const initTargetConfig = ActionFactory.createRequestRoutine('TARGET_CONFIG_INIT_PUSH', namespace);
export const generateTargetConfig = ActionFactory.createRequestRoutine<void, TargetConfigParams>(
  'TARGET_CONFIG_GENERATE_PUSH',
  namespace
);
export const pushTargetConfigForm = ActionFactory.createAction<TargetConfigParams>(
  'TARGET_CONFIG_FORM_PUSH',
  namespace
);
//
// // objective configs
export const initAdProfileObjective = ActionFactory.createAction('AD_PROFILE_OBJECTIVE_PUSH', namespace);
export const initGameConfigObjective = ActionFactory.createAction('GAME_CONFIG_OBJECTIVE_PUSH', namespace);
export const generateAdProfileObjective = ActionFactory.createRequestRoutine<void, AdProfileObjectiveForm>(
  'AD_PROFILE_OBJECTIVE_GENERATE',
  namespace
);
export const fetchObjectiveConfig = ActionFactory.createRequestRoutine<void, ControlGroupByCountry>(
  'OBJECTIVE_CONFIG_FETCH',
  namespace
);
export const pushAdProfileObjectiveForm = ActionFactory.createAction<AdProfileObjectiveForm>(
  'AD_PROFILE_OBJECTIVE_FORM_PUSH',
  namespace
);
export const pushGameConfigObjectiveForm = ActionFactory.createAction<GameConfigObjectiveForm>(
  'GAME_CONFIG_OBJECTIVE_FORM_PUSH',
  namespace
);

export const fetchGameConfigParams = ActionFactory.createRequestRoutine<UniqueId, GLDParamDto[] | null>(
  'GAME_CONFIG_PARAMS_FETCH',
  namespace
);
export const pushClone = ActionFactory.createRequestRoutine<void, DirectConfigFormState | null>(
  'CLONE_PUSH',
  namespace
);
export const validateOverlapping = ActionFactory.createRequestRoutine<void, OverlappedConfigDto[]>(
  'VALIDATE_OVERLAPPING_EXPERIMENTS',
  namespace
);
export const fetchLiveConfigs = ActionFactory.createRequestRoutine<void, ILiveConfig[]>(
  'LIVE_CONFIGS_FETCH',
  namespace
);
export const clearAll = ActionFactory.createAction('CLEAR_ALL', namespace);
