import React from 'react';
import { FieldArrayWithId, UseFormWatch } from 'react-hook-form';

import { UniqueId } from '@domain/types';
import { EditableParamName } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/objectiveConfig/configInput/atoms/EditableParamName';
import { InDevFormParams } from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';

type Props = {
  watch: UseFormWatch<InDevFormParams>;
  configs: FieldArrayWithId<InDevFormParams, 'config'>[];
  handleRemoveKey: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleUpdateKey: (index: UniqueId, value: string) => void;
};

export function Thead({ configs, handleRemoveKey, watch, handleUpdateKey }: Props) {
  return (
    <thead>
      <tr>
        {configs.map((param, index) => (
          <EditableParamName
            key={param.id}
            param={param.key}
            paramIndex={index}
            watch={watch}
            handleRemoveKey={handleRemoveKey}
            handleUpdateKey={handleUpdateKey}
          />
        ))}
      </tr>
    </thead>
  );
}
