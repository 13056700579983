import React from 'react';
import { UseFormResetField, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { BasicInfoParams } from '@domain/models/createExperiment/BasicInfoParams';

import { NextButton } from '@components/layout/NextButton/NextButton';
import { ExperimentTypeInput } from '@pages/createExperiment/basicInfo/inputs/ExperimentTypeInput';
import { ExperimentNameInput } from '@pages/createExperiment/basicInfo/inputs/ExperimentNameInput';
import { GameNameInput } from '@pages/createExperiment/basicInfo/inputs/GameNameInput';
import { PlatformInput } from '@pages/createExperiment/basicInfo/inputs/PlatformInput';
import { BundleIdInput } from '@pages/createExperiment/basicInfo/inputs/BundleIdInput';
import { FirebaseIdInput } from '@pages/createExperiment/basicInfo/inputs/FirebaseIdInput';
import { DescriptionInput } from '@pages/createExperiment/basicInfo/inputs/DescriptionInput';
import { RecommendedProfileInput } from '@pages/createExperiment/basicInfo/inputs/RecommendedProfileInput';

import styles from './BasicInfo.module.scss';

type Props = FormComponent<BasicInfoParams> & {
  watch: UseFormWatch<BasicInfoParams>;
  resetField: UseFormResetField<BasicInfoParams>;
  setValue: UseFormSetValue<BasicInfoParams>;
};

export function BasicInfoForm({ errors, handleSubmit, watch, control, register, setValue, resetField }: Props) {
  const experimentType = watch('experimentType');
  const isGLD = experimentType === ExperimentType.GLD_TEST;

  return (
    <form className={styles.basicInfo} onSubmit={handleSubmit}>
      <ExperimentTypeInput register={register} control={control} />
      <ExperimentNameInput register={register} errors={errors} />
      <GameNameInput control={control} watch={watch} />
      <PlatformInput control={control} watch={watch} setValue={setValue} resetField={resetField} />
      <BundleIdInput control={control} watch={watch} setValue={setValue} />
      <FirebaseIdInput control={control} watch={watch} setValue={setValue} />
      {!isGLD && <RecommendedProfileInput register={register} />}
      {isGLD && <DescriptionInput register={register} errors={errors} />}
      <NextButton />
    </form>
  );
}
