import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { TextInput } from 'crazy-ui-next';

import { BasicInfoParams } from '@pages/createDirectConfig/basicInfo/types/BasicInfoParams';
import styles from '@pages/createExperiment/basicInfo/BasicInfo.module.scss';
import { getInputProps } from '@ui/hooks/form';

type Props = {
  register: UseFormRegister<BasicInfoParams>;
  errors: FieldErrors<BasicInfoParams>;
};

export function NameInput({ register, errors }: Props) {
  return (
    <TextInput
      labelText="Targeting Name:"
      placeholder="Targeting Name:"
      maxLength={30}
      className={styles.input}
      {...getInputProps<BasicInfoParams>('name', register, errors)}
    />
  );
}
