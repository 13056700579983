import React from 'react';
import { ReactComponent as WarningSvg } from '@assets/svg/warning.svg';

import styles from '@pages/createExperiment/notifications/Notification.module.scss';

type Props = {
  title: string;
  children: React.ReactNode;
};

export function Notification({ title, children }: Props) {
  return (
    <div className={styles.notification}>
      <div className={styles.header}>
        <WarningSvg />
        <h4>{title}</h4>
      </div>
      {children}
    </div>
  );
}
