import React, { useCallback, useEffect, useState } from 'react';
import { IsNotEmpty } from 'class-validator';
import { UseFormWatch } from 'react-hook-form';
import { Button, ButtonVariant, TextInput } from 'crazy-ui-next';

import { getInputProps, useForm } from '@ui/hooks/form';

import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';
import { ReactComponent as AcceptSvg } from '@assets/svg/accept.svg';

import styles from '../ConfigInput.module.scss';
import { InDevFormParams } from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';

class Param {
  @IsNotEmpty()
  value: string;
}

type Props = {
  handleAddParam: (key: string) => void;
  watch: UseFormWatch<InDevFormParams>;
};

export function AddParamBtn({ handleAddParam, watch }: Props) {
  const config = watch('config');
  const formattedParams = config.map(({ key }) => key.toLowerCase());
  const [isEdit, setIsEdit] = useState(false);

  const {
    register,
    handleSubmit,
    resetField,
    setFocus,
    setError,
    formState: { errors }
  } = useForm<Param>({ schema: Param });

  const handleEdit = useCallback(() => {
    setIsEdit(true);
  }, [setIsEdit]);

  const handleAdd = handleSubmit((body) => {
    if (formattedParams.includes(body.value.toLowerCase())) {
      setError('value', {
        type: 'custom',
        message: 'Param name should be uniq'
      });
      return;
    }

    handleAddParam(body.value);
    setIsEdit(false);
    resetField('value');
  });

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleAdd();
      }
      if (event.key === 'Escape') {
        setIsEdit(false);
        resetField('value');
      }
    },
    [setIsEdit, handleAdd, resetField]
  );

  useEffect(() => {
    if (isEdit) {
      setFocus('value');
    }
  }, [isEdit, setFocus]);

  return (
    <div className={styles.addVarWrapper}>
      {isEdit ? (
        <div>
          <TextInput onKeyDown={handleKeyDown} {...getInputProps<Param>('value', register, errors)} />
          <Button variant={ButtonVariant.TERTIARY} onClick={handleAdd} icon={<AcceptSvg />} />
        </div>
      ) : (
        <div>
          <Button variant={ButtonVariant.TERTIARY} onClick={handleEdit} icon={<PlusSvg />}>
            Var
          </Button>
        </div>
      )}
    </div>
  );
}
