import { ControlGroupForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/types/AdProfileObjectiveForm';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ObjectiveMapper } from '@app/mappers/experiment/ObjectiveMapper';
import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';
import { parseInt } from 'lodash-es';

export class AdProfileFormMapper {
  static formatFormValue(value: string, type: ExperimentVariableType): any {
    switch (type) {
      case ExperimentVariableType.INTEGER:
        return parseInt(value);
      case ExperimentVariableType.BOOLEAN:
        return value === '1';
      case ExperimentVariableType.INT_LIST:
        return value.split(',').map(Number);
      case ExperimentVariableType.STRING:
      default:
        return value;
    }
  }

  static toGenericConfig(
    params: string[],
    controlGroup: ControlGroupForm,
    variables: ExperimentVariableDto[]
  ): GenericConfigEntry {
    const entry = params.reduce((acc, param) => {
      const variable = variables.find(({ name }) => name === param);

      if (!variable) {
        return acc;
      }

      const { sessionsSupported, type } = variable;

      const sessionType = sessionsSupported
        ? ExperimentObjectiveSessionType.SESSION
        : ExperimentObjectiveSessionType.PLAIN;

      const wrapperType = ObjectiveMapper.mapVariableTypeToWrapperType(type);

      const formattedValue = this.formatFormValue(controlGroup[param], type);

      const configValue = sessionsSupported
        ? { 1: { type: wrapperType, value: formattedValue } }
        : { type: wrapperType, value: formattedValue };

      return {
        ...acc,
        [param]: {
          type: sessionType,
          configValue
        }
      };
    }, {});

    return {
      name: 'A',
      description: null,
      active: true,
      entry
    };
  }
}
