import { Service } from 'typedi';

import { UniqueId } from '@domain/types';
import { DirectConfigApi } from '@infrastructure/api/DirectConfigApi';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { Paginate } from '@domain/models/Paginate';
import { OffsetPagination } from '@domain/models/local/OffsetPagination';
import { EnvConfig } from '@app/config';
import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { directConfigListSelectors } from '@infrastructure/store/directConfigList/directConfigListSelectors';
import { IUpdateDirectConfig } from '@domain/models/directConfig/UpdateDirectConfig';
import { INewDirectConfig } from '@domain/models/directConfig/NewDirectConfig';

@Service()
export class DirectConfigService {
  constructor(private readonly api: DirectConfigApi, private readonly reduxRepo: ReduxRepo) {}

  async create(body: INewDirectConfig): Promise<IDirectConfig> {
    return this.api.create(body);
  }

  async get(id: UniqueId): Promise<IDirectConfig> {
    return this.api.get(id);
  }

  async getList(page?: number): Promise<Paginate<IDirectConfig>> {
    const pagination = new OffsetPagination(0, EnvConfig.PER_PAGE);

    if (page) {
      pagination.setPage(page);
    }

    const filters = this.reduxRepo.findBy(directConfigListSelectors.getRequestParams);

    return this.api.getList(filters, pagination);
  }

  async update(id: UniqueId, body: Partial<IUpdateDirectConfig>): Promise<IDirectConfig> {
    return this.api.update(id, body);
  }
}
