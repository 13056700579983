import React, { ReactElement } from 'react';

import { UniqueId } from '@domain/types';
import { BasicInfoContainer } from '@pages/createDirectConfig/basicInfo/BasicInfoContainer';
import { TargetConfigContainer } from '@pages/createDirectConfig/targetConfig/TargetConfigContainer';
import { ObjectiveConfigContainer } from '@pages/createDirectConfig/objectiveConfig/ObjectiveConfigContainer';

export type DirectConfigFormStep = {
  title: string;
  container: ReactElement;
  index: UniqueId;
};

export const steps: DirectConfigFormStep[] = [
  {
    title: 'Basic Info',
    container: <BasicInfoContainer />,
    index: 1
  },
  {
    title: 'Targeting Config',
    container: <TargetConfigContainer />,
    index: 2
  },
  {
    title: 'Objective Config',
    container: <ObjectiveConfigContainer />,
    index: 3
  }
];
