import React, { useCallback } from 'react';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { pushSetStep } from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { Steps } from '@pages/createDirectConfig/steps/Steps';
import { RequestStage } from '@infrastructure/store/types/actions';

export function StepsContainer() {
  const dispatch = useDispatch();
  const currentStep = useSelector(createDirectConfigSelectors.getStep);

  const status = useSelector(createDirectConfigSelectors.getStatus);
  const isRequest = status === RequestStage.REQUEST;

  const handleSetStep = useCallback(
    (e) => {
      const { step } = e.currentTarget.dataset;
      dispatch(pushSetStep(Number(step)));
    },
    [dispatch]
  );

  return <Steps currentStep={currentStep} isRequest={isRequest} handleSetStep={handleSetStep} />;
}
