import { combineReducers, createReducer } from '@reduxjs/toolkit';

import { clearAll, fetchObjectiveConfig } from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { ControlGroupByCountry } from '@domain/models/ControlGroup';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';

const controlGroupsInitial: ControlGroupByCountry = {};

export const controlGroupsDataReducer = createReducer(controlGroupsInitial, (qb) => {
  qb.addCase(fetchObjectiveConfig.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return controlGroupsInitial;
  });
});

export const controlGroupsReducer = combineReducers({
  status: ReducerFactory.makeStatus([fetchObjectiveConfig], clearAll),
  data: controlGroupsDataReducer
});
