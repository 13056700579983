import { OverlappedConfigDto } from '@domain/models/experiment/OverlappedConfigDto';
import { Row } from '@pages/createDirectConfig/notifications/overlappedConfigs/list/Row';

type Props = {
  configs: OverlappedConfigDto[];
  firebaseId: string;
};

export function List({ configs, firebaseId }: Props) {
  return (
    <ul>
      {configs.map((config) => (
        <Row key={config.id} config={config} firebaseId={firebaseId} />
      ))}
    </ul>
  );
}
