import React, { useCallback } from 'react';
import { DropdownEvent, DropdownMultiInput, DropdownOption } from 'crazy-ui-next';

import { ReactComponent as CheckListSvg } from '@assets/svg/check-list.svg';
import { DefaultFormValue, DefaultFormValueLabels, FormComponent } from '@ui/hooks/form';
import { DirectConfigFiltersForm } from '@pages/directConfigList/filters/types/DirectConfigFiltersForm';
import { useController } from 'react-hook-form';
import { DirectConfigState, DirectConfigStateLabels } from '@domain/enums/directConfig/DirectConfigState';

const options: DropdownOption<DirectConfigState | DefaultFormValue>[] = [
  { value: DefaultFormValue.ALL, label: DefaultFormValueLabels[DefaultFormValue.ALL] },
  { value: DirectConfigState.LIVE, label: DirectConfigStateLabels[DirectConfigState.LIVE] },
  { value: DirectConfigState.IN_DEV, label: DirectConfigStateLabels[DirectConfigState.IN_DEV] },
  { value: DirectConfigState.STOPPED, label: DirectConfigStateLabels[DirectConfigState.STOPPED] },
  { value: DirectConfigState.CANCELED, label: DirectConfigStateLabels[DirectConfigState.CANCELED] }
];

type Props = Pick<FormComponent<DirectConfigFiltersForm>, 'control'>;

export function StatusInput({ control }: Props) {
  const { field } = useController({ control, name: 'states' });

  const handleChange = useCallback(
    (e: DropdownEvent<DirectConfigState | DefaultFormValue>) => {
      const { value } = e;

      if (value === DefaultFormValue.ALL) {
        const statuses = options.map((option) => option.value);
        const formattedValue = field.value.length === statuses.length ? [] : statuses;
        field.onChange(formattedValue);
        return;
      }
      if (field.value.includes(value)) {
        field.onChange(field.value.filter((v) => v !== value && v !== DefaultFormValue.ALL));
        return;
      }

      field.onChange([...field.value, value]);
    },
    [field]
  );

  return (
    <DropdownMultiInput
      name={field.name}
      inputIcon={<CheckListSvg />}
      value={field.value}
      options={options}
      handleChange={handleChange}
      labelText="Status"
    />
  );
}
