import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { authSelectors } from '@infrastructure/store/auth/authSelectors';
import { Routing } from '@infrastructure/routing';
import { Actions, SplitButtonOption } from '@pages/experimentDetails/components/actions/Actions';
import {
  ACTIONS,
  ACTIONS_ICON_MAP,
  ACTIONS_TITLE_MAP,
  getActionsByUserRole
} from '@pages/experimentDetails/components/actions/config';
import { ConfirmModal } from '@components/layout/confirmModal/ConfirmModal';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';
import {
  pushCancelExperiment,
  pushScheduleGLDExperiment
} from '@infrastructure/store/experimentDetails/experimentDetailsActions';

export function ActionsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showStopModal, handleStopModal] = useState(false);
  const userRole = useSelector(authSelectors.getCurrentRole);

  const experiment = useSelector(experimentDetailsSelectors.getExperiment);
  const status = useSelector(experimentDetailsSelectors.getActionsStatus);

  const isLoading = status === RequestStage.REQUEST;

  const { state, experimentId, experimentName } = experiment;

  const handleOpenStopModal = useCallback(() => {
    handleStopModal(true);
  }, []);

  const handleCloseStopModal = useCallback(() => {
    handleStopModal(false);
  }, []);

  const handleStop = useCallback(() => {
    dispatch(pushCancelExperiment.trigger(experimentId));
    handleCloseStopModal();
  }, [dispatch, handleCloseStopModal, experimentId]);

  const handleScheduleGLD = useCallback(() => {
    dispatch(pushScheduleGLDExperiment.trigger(experimentId));
  }, [dispatch, experimentId]);

  const handleBack = useCallback(() => {
    history.push(Routing.getExperimentList());
  }, [history]);

  const handleClone = useCallback(() => {
    history.push(Routing.getCloneExperiment(experimentId));
  }, [history, experimentId]);

  const handleClick = useCallback(
    (e) => {
      const { value } = e.currentTarget;

      switch (value) {
        case ACTIONS.STOP:
          handleOpenStopModal();
          break;
        case ACTIONS.SCHEDULE_GLD:
          handleScheduleGLD();
          break;
        case ACTIONS.CLONE:
          handleClone();
          break;
        case ACTIONS.BACK:
        default:
          handleBack();
          break;
      }
    },
    [handleOpenStopModal, handleScheduleGLD, handleBack, handleClone]
  );

  const actions = getActionsByUserRole(state, userRole);
  const options: SplitButtonOption[] = [{ value: 'default', label: 'Actions' }];
  actions.forEach((action) => {
    options.push({ value: action, label: ACTIONS_TITLE_MAP[action], icon: ACTIONS_ICON_MAP[action] });
  });

  return (
    <>
      <Actions options={options} handleClick={handleClick} disabled={isLoading} />
      {showStopModal && (
        <ConfirmModal
          title="Stop Experiment?"
          subtitle={`Are you sure you want to stop experiment "${experimentName}"?`}
          handleModal={handleCloseStopModal}
          handleConfirm={handleStop}
        />
      )}
    </>
  );
}
