export interface UserPropertyInput {
  operatorId: string;
  value: string;
  userPropertyId: string;
  userPropertyDisplayName: string;
  operatorDisplayName: string;
}

export class UserPropertiesParams {
  inputs: UserPropertyInput[];
}
