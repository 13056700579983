import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushTargetConfigForm,
  generateTargetConfig,
  pushClone
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { TargetConfigParams } from '@pages/createDirectConfig/targetConfig/types/TargetConfigParams';

const targetConfigInitial: TargetConfigParams = new TargetConfigParams();

export const targetConfigReducer = createReducer(targetConfigInitial, (qb) => {
  qb.addCase(pushClone.success, (state, { payload }) => {
    if (payload) {
      return payload.targetConfig;
    }
    return state;
  });
  qb.addCase(generateTargetConfig.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushTargetConfigForm, (state, { payload: form }) => {
    return form;
  });
  qb.addCase(clearAll, () => {
    return new TargetConfigParams();
  });
});
