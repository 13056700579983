import React from 'react';
import { DropdownInput, DropdownOption } from 'crazy-ui-next';

import { FormComponent } from '@ui/hooks/form';
import { TargetConfigParams } from '@pages/createDirectConfig/targetConfig/types/TargetConfigParams';
import { useController } from 'react-hook-form';
import { VersionOperator, VersionOperatorLabels } from '@domain/enums/VersionOperator';

import styles from '@pages/createDirectConfig/targetConfig/TargetConfig.module.scss';

const options: DropdownOption<VersionOperator>[] = Object.values(VersionOperator).map((value) => ({
  value,
  label: VersionOperatorLabels[value]
}));

type Props = Pick<FormComponent<TargetConfigParams>, 'control'>;

const name = 'appliedOperator';

export function VersionOperatorInput({ control }: Props) {
  const { field } = useController<TargetConfigParams>({ control, name });

  return (
    <DropdownInput
      className={styles.dropdown}
      name={name}
      value={field.value}
      options={options}
      labelText="Versions"
      handleChange={(event) => field.onChange(event.value)}
    />
  );
}
