import React from 'react';
import { FieldArrayWithId, UseFormSetFocus } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { EditableCell } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/objectiveConfig/configInput/atoms/EditableCell';
import { InDevFormParams } from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';

type Props = Pick<FormComponent<InDevFormParams>, 'control'> & {
  setFocus: UseFormSetFocus<InDevFormParams>;
  configs: FieldArrayWithId<InDevFormParams, 'config'>[];
};

export function TBody({ configs, control, setFocus }: Props) {
  return (
    <tbody>
      <tr>
        {configs.map((config, index) => (
          <EditableCell key={config.key} control={control} index={index} />
        ))}
      </tr>
    </tbody>
  );
}
