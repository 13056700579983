import React from 'react';

import { Summary } from '@pages/directConfigDetails/components/summary/Summary';
import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';
import { useSelector } from '@ui/hooks/redux';
import { useToggle } from '@ui/hooks/useToggle';

export function SummaryContainer() {
  const { isActive, handleToggle } = useToggle(true);

  const directConfig = useSelector(directConfigDetailsSelectors.getDirectConfig);

  return <Summary directConfig={directConfig} isActive={isActive} handleToggle={handleToggle} />;
}
