import React from 'react';
import { useController } from 'react-hook-form';
import { TextInput } from 'crazy-ui-next';

import { FormComponent } from '@ui/hooks/form';
import { DirectConfigFiltersForm } from '@pages/directConfigList/filters/types/DirectConfigFiltersForm';

type Props = Pick<FormComponent<DirectConfigFiltersForm>, 'control'>;

export function SearchInput({ control }: Props) {
  const { field } = useController({ control, name: 'name' });

  return (
    <TextInput
      labelText="Search"
      placeholder="Search"
      value={field.value}
      onChange={field.onChange}
      name={field.name}
    />
  );
}
