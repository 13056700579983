import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';
import cn from 'classnames';

import styles from '../../../../DirectConfigDetails.module.scss';

type Props = {
  openEditor: () => void;
  closeEditor: () => void;
  editorEnabled: boolean;
  submitLabel?: string;
  handleSubmit: () => void;
  isLoading?: boolean;
  className?: string;
  isSubmitDisabled: boolean;
  isEditDisabled: boolean;
};

export function FormControls({
  openEditor,
  closeEditor,
  editorEnabled,
  submitLabel,
  handleSubmit,
  isSubmitDisabled,
  isLoading,
  className,
  isEditDisabled
}: Props) {
  if (editorEnabled) {
    return (
      <div className={cn(styles.formControls, className)}>
        <Button variant={ButtonVariant.SECONDARY} onClick={closeEditor} disabled={isLoading}>
          Cancel
        </Button>
        <Button disabled={isSubmitDisabled} onClick={handleSubmit} isLoading={isLoading}>
          {submitLabel || 'Save'}
        </Button>
      </div>
    );
  }
  return (
    <div className={cn(styles.formControls, className)}>
      <Button onClick={openEditor} variant={ButtonVariant.SECONDARY} isLoading={isLoading} disabled={isEditDisabled}>
        Edit
      </Button>
    </div>
  );
}
