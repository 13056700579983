import { combineReducers } from '@reduxjs/toolkit';

import { directConfigReducer } from '@infrastructure/store/directConfigDetails/reducers/directConfigReducer';
import { gameStatsReducer } from '@infrastructure/store/directConfigDetails/reducers/gameStatsReducer';
import { defaultRegionsReducer } from '@infrastructure/store/directConfigDetails/reducers/defaultRegionsReducer';
import { controlGroupsReducer } from '@infrastructure/store/directConfigDetails/reducers/controlGroupsReducer';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { clearAll, pushStateUpdate } from '@infrastructure/store/directConfigDetails/directConfigDetailsActions';

export const directConfigDetails = combineReducers({
  directConfig: directConfigReducer,
  gameStats: gameStatsReducer,
  controlGroups: controlGroupsReducer,
  defaultRegions: defaultRegionsReducer,
  actionsStatus: ReducerFactory.makeStatus([pushStateUpdate], clearAll)
});
