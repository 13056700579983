import { ArrayNotEmpty, IsArray, IsEnum, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

import { FormSummary } from '@pages/createExperiment/atoms/Summary/Summary';
import { ExperimentRegionName } from '@domain/enums/ExperimentRegion';
import { VersionInput } from '@domain/models/VersionInput';
import { UserPropertiesParams } from '@domain/models/createExperiment/userProperties/UserPropertiesParams';
import { VersionOperator } from '@domain/enums/VersionOperator';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';

export class RegionOption {
  value: string;
  label: string;
}

export class TargetConfigParams {
  constructor(directConfig?: IDirectConfig) {
    if (!directConfig) {
      return this;
    }

    this.regions = directConfig.liveRegions.map(({ name }) => ({ value: name, label: name }));
    this.regionsForInDev = directConfig.inDevRegions.map(({ name }) => ({ value: name, label: name }));
    this.versions = directConfig.appVersions.map((version) => ({ value: version }));
    this.appliedOperator = directConfig.appliedOperator;
    this.importedSegments = directConfig.importedSegments;
    const initUserProperties: UserPropertiesParams = { inputs: [] };

    // todo [Tech-debt] [CU-1642] - move to mapper and replace every similar usage
    this.userProperties = directConfig.userProperties
      ? directConfig.userProperties.reduce((acc, property) => {
          acc.inputs.push({
            operatorId: property.operatorId.toString(),
            value: property.value,
            userPropertyId: property.userPropertyId.toString(),
            userPropertyDisplayName: property.userPropertyDisplayName,
            operatorDisplayName: property.operatorDisplayName
          });

          return acc;
        }, initUserProperties)
      : initUserProperties;
  }

  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => RegionOption)
  regions: RegionOption[] = [{ value: ExperimentRegionName.US, label: ExperimentRegionName.US }];

  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => RegionOption)
  regionsForInDev: RegionOption[] = [{ value: ExperimentRegionName.MK, label: ExperimentRegionName.MK }];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => VersionInput)
  versions: VersionInput[] = [{ value: '' }];

  @IsEnum(VersionOperator)
  appliedOperator: VersionOperator = VersionOperator.GREATER_OR_EQUAL;

  @IsString()
  @IsOptional()
  importedSegments: string = '';

  userProperties: UserPropertiesParams = { inputs: [] };

  public pushRegions(regionName: RegionOption) {
    this.regions.push(regionName);
    return this;
  }

  public setVersion(version: string) {
    this.versions = [{ value: version }];
    return this;
  }

  private getUserPropertiesLabel(userProperties: UserPropertiesParams): string {
    const { inputs } = userProperties;
    const nonEmptyValues = inputs.filter(({ value }) => value.trim().length);

    if (!nonEmptyValues.length) {
      return '-';
    }

    return nonEmptyValues
      .map(
        ({ userPropertyDisplayName, operatorDisplayName, value }) =>
          `${userPropertyDisplayName} ${operatorDisplayName} ${value}`
      )
      .join(', ');
  }

  getSummary(): FormSummary {
    return [
      { title: 'Live Regions', value: this.regions.map(({ value }) => value).join(', ') },
      { title: 'In Dev Regions', value: this.regionsForInDev.map(({ value }) => value).join(', ') },
      {
        title: 'Versions',
        value: this.versions.filter((x) => x.value.trim().length).length
          ? this.versions
              .filter(({ value }) => value)
              .map(({ value }) => value)
              .join(', ')
          : 'All versions'
      },
      { title: 'Imported Segments', value: this.importedSegments || '-' },
      { title: 'User Properties', value: this.getUserPropertiesLabel(this.userProperties) }
    ];
  }
}
