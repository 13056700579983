import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { clearAll, fetchDefaultRegions } from '@infrastructure/store/directConfigDetails/directConfigDetailsActions';
import { RegionDto } from '@domain/models/RegionDto';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';

const regionsInitial: RegionDto[] = [] as RegionDto[];

const defaultRegionsDataReducer = createReducer(regionsInitial, (qb) => {
  qb.addCase(fetchDefaultRegions.success, (state, { payload }) => {
    return payload;
  });

  qb.addCase(clearAll, () => {
    return regionsInitial;
  });
});

export const defaultRegionsReducer = combineReducers({
  data: defaultRegionsDataReducer,
  status: ReducerFactory.makeStatus(fetchDefaultRegions, clearAll)
});
