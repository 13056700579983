import React from 'react';
import { FieldArrayWithId, UseFormSetFocus } from 'react-hook-form';

import { GameConfigObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';
import { ConfigName } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/components/inputGroup/atoms/ConfigName';
import { EditableDescription } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/components/inputGroup/atoms/EditableDescription';
import { EditableCell } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/components/inputGroup/atoms/EditableCell';
import { FormComponent } from '@ui/hooks/form';

type Props = Pick<FormComponent<GameConfigObjectiveForm>, 'control'> & {
  setFocus: UseFormSetFocus<GameConfigObjectiveForm>;
  configs: FieldArrayWithId<GameConfigObjectiveForm, 'config'>[];
};

export function TBody({ configs, control, setFocus }: Props) {
  return (
    <tbody>
      {configs.map((config, configIndex) => (
        <tr key={config.id}>
          <ConfigName name={config.name} />
          <EditableDescription control={control} configIndex={configIndex} setFocus={setFocus} />
          {config.input.map((input, inputIndex) => (
            <EditableCell key={input.key} control={control} configIndex={configIndex} inputIndex={inputIndex} />
          ))}
        </tr>
      ))}
    </tbody>
  );
}
