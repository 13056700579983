import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { TextInput } from 'crazy-ui-next';

import { FormComponent, getInputProps } from '@ui/hooks/form';
import { VersionOperator } from '@domain/enums/VersionOperator';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';

import styles from '../../WinnerConfigModal.module.scss';

type Props = FormComponent<UpdateWinnerConfigParams> & {};

export function VersionInputContainer({ control, register, errors }: Props) {
  const versionOperator = useWatch<UpdateWinnerConfigParams>({ control, name: 'versionOperator' });
  const [maxVersions, setMaxVersions] = useState(5);

  const { fields, append, remove } = useFieldArray({
    name: 'version',
    control
  });

  const onAdd = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  useEffect(() => {
    if (versionOperator === VersionOperator.EQUAL) {
      setMaxVersions(5);
    } else {
      const indexesToRemove = fields.slice(1).map((_, index) => index + 1);

      remove(indexesToRemove);
      setMaxVersions(1);
    }
  }, [versionOperator, fields, remove]);

  const inputs = fields.map((field, index) => (
    <TextInput
      className={styles.multiInputEl}
      id={`winner-version-input-${index}`}
      key={`version_input_${index}`}
      {...getInputProps<UpdateWinnerConfigParams>(`version.${index}.value`, register, errors)}
    />
  ));

  return <MultiTextInputContainer inputs={inputs} onAddItem={onAdd} onRemoveItem={onRemove} maxItems={maxVersions} />;
}
