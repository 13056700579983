import React from 'react';
import { useController, Control, UseFormSetValue, FieldValues, Path } from 'react-hook-form';
import { DropdownInput, DropdownOption } from 'crazy-ui-next';

import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';

import styles from '@pages/createExperiment/targetConfig/userProperties/UserProperties.module.scss';

type Props<T extends FieldValues> = {
  inputIndex: number;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  operators: UserPropertyOperator[];
  disabled: boolean;
  watchName: Path<T>;
};

export function UserPropertyOperatorInput<T extends FieldValues>({
  inputIndex,
  control,
  operators,
  setValue,
  disabled,
  watchName
}: Props<T>) {
  const inputName = `${watchName}.${inputIndex}.operatorId`;

  const options: DropdownOption<string>[] = operators.map((operator) => ({
    label: operator.displayName,
    value: String(operator.id)
  }));

  const { field } = useController({
    // @ts-ignore-next-line
    name: inputName,
    control
  });

  const handleChange = (event) => {
    const { value } = event;
    const operator = operators.find((property) => property.id === +value);
    const displayName = operator ? operator.displayName : '';

    // @ts-ignore-next-line
    setValue(`${watchName}.${inputIndex}.operatorId`, value);
    // @ts-ignore-next-line
    setValue(`${watchName}.${inputIndex}.operatorDisplayName`, displayName);
  };

  return (
    <DropdownInput
      options={options}
      name={inputName}
      value={field.value}
      handleChange={handleChange}
      disabled={disabled}
      className={styles.dropdown}
    />
  );
}
