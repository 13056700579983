import React from 'react';

import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';

type Props = {
  config: GenericConfigEntry;
};

export function DescriptionCell({ config }: Props) {
  const { description } = config;

  return (
    <td data-wider={true}>
      <span>{description}</span>
    </td>
  );
}
