import { combineReducers, createReducer } from '@reduxjs/toolkit';
import {
  clearAll,
  fetchDirectConfig,
  initDirectConfigDetails,
  pushInDevUpdate,
  pushRunningUpdate,
  pushStateUpdate
} from '@infrastructure/store/directConfigDetails/directConfigDetailsActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';

const dataInitial: IDirectConfig = {} as IDirectConfig;

const dataReducer = createReducer(dataInitial, (qb) => {
  qb.addCase(initDirectConfigDetails.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(fetchDirectConfig.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushInDevUpdate.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushRunningUpdate.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushStateUpdate.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return dataInitial;
  });
});

export const directConfigReducer = combineReducers({
  status: ReducerFactory.makeStatus(
    [initDirectConfigDetails, fetchDirectConfig, pushInDevUpdate, pushRunningUpdate],
    clearAll
  ),
  data: dataReducer
});
