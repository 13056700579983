import React from 'react';
import { UseFormResetField, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { NextButton } from '@components/layout/NextButton/NextButton';
import { BasicInfoParams } from '@pages/createDirectConfig/basicInfo/types/BasicInfoParams';
import { TypeInput } from '@pages/createDirectConfig/basicInfo/inputs/TypeInput';
import { NameInput } from '@pages/createDirectConfig/basicInfo/inputs/NameInput';
import { GameNameInput } from '@pages/createDirectConfig/basicInfo/inputs/GameNameInput';
import { PlatformInput } from '@pages/createDirectConfig/basicInfo/inputs/PlatformInput';
import { BundleIdInput } from '@pages/createDirectConfig/basicInfo/inputs/BundleIdInput';
import { FirebaseIdInput } from '@pages/createDirectConfig/basicInfo/inputs/FirebaseIdInput';

import styles from './BasicInfo.module.scss';

type Props = FormComponent<BasicInfoParams> & {
  watch: UseFormWatch<BasicInfoParams>;
  resetField: UseFormResetField<BasicInfoParams>;
  setValue: UseFormSetValue<BasicInfoParams>;
};

// todo [Tech-debt] [CU-1642] generalize this form to be re-used in create experiment & create direct config
export function BasicInfoForm({ errors, handleSubmit, watch, control, register, setValue, resetField }: Props) {
  return (
    <form className={styles.basicInfo} onSubmit={handleSubmit}>
      <TypeInput register={register} control={control} />
      <NameInput register={register} errors={errors} />
      <GameNameInput control={control} watch={watch} />
      <PlatformInput control={control} watch={watch} setValue={setValue} resetField={resetField} />
      <BundleIdInput control={control} watch={watch} setValue={setValue} />
      <FirebaseIdInput control={control} watch={watch} setValue={setValue} />
      <NextButton />
    </form>
  );
}
