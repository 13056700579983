import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  generateAdProfileObjective,
  pushAdProfileObjectiveForm,
  pushClone
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { AdProfileObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/types/AdProfileObjectiveForm'; // fixme generalize

const adProfileInitial: AdProfileObjectiveForm = new AdProfileObjectiveForm();

export const objectiveAdProfileReducer = createReducer(adProfileInitial, (qb) => {
  qb.addCase(pushClone.success, (state, { payload }) => {
    if (payload) {
      return payload.adProfileObjective;
    }
    return state;
  });
  qb.addCase(pushAdProfileObjectiveForm, (state, { payload }) => {
    return payload;
  });
  qb.addCase(generateAdProfileObjective.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return new AdProfileObjectiveForm();
  });
});
