import React from 'react';
import { FieldArrayWithId, UseFormWatch } from 'react-hook-form';

import { EditableParamName } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/components/inputGroup/atoms/EditableParamName';
import { GameConfigObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';

type Props = {
  watch: UseFormWatch<GameConfigObjectiveForm>;
  params: FieldArrayWithId<GameConfigObjectiveForm, 'params'>[];
  handleRemoveParam: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleUpdateParam: (newParam: string, index: number) => void;
};

export function Thead({ params, handleRemoveParam, watch, handleUpdateParam }: Props) {
  return (
    <thead>
      <tr>
        <th />
        <th>Group Name</th>
        {params.map((param, index) => (
          <EditableParamName
            key={param.id}
            param={param.value}
            paramIndex={index}
            watch={watch}
            handleRemoveParam={handleRemoveParam}
            handleUpdateParam={handleUpdateParam}
          />
        ))}
      </tr>
    </thead>
  );
}
