import React from 'react';
import { TextInput } from 'crazy-ui-next';

import { GameConfigObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';
import { FormComponent, getInputProps } from '@ui/hooks/form';

type Props = Pick<FormComponent<GameConfigObjectiveForm>, 'register'>;

export function DefaultValueInput({ register }: Props) {
  return (
    <TextInput
      labelText="Default value"
      placeholder="Default value"
      {...getInputProps<GameConfigObjectiveForm>('defaultValue', register, {})}
    />
  );
}
