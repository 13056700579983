import React, { useCallback } from 'react';
import { CheckboxInput } from 'crazy-ui-next';

import { IterationResultDto } from '@domain/models/iteration/IterationResultDto';
import { metricMeta } from '@pages/experimentDetails/components/stats/components/result/components/filters/components/toggleColumns/utils/metricKeys';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { toggleSelectedRow } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';

type Props = {
  result: IterationResultDto;
  metricKeys: string[];
  isAB: boolean;
};
export function TRow({ result, metricKeys, isAB }: Props) {
  const dispatch = useDispatch();
  const selectedRows = useSelector(experimentDetailsSelectors.getSelectedRows);
  const {
    group,
    color,
    parametersConfig: { entry, description }
  } = result;
  const values = metricKeys.map((key) => <td key={key}>{metricMeta[key].format(result[key])}</td>);

  const handleChange = useCallback(
    (e) => {
      const { name } = e.currentTarget;

      dispatch(toggleSelectedRow(name));
    },
    [dispatch]
  );

  return (
    <tr data-color={color}>
      <td>
        <CheckboxInput name={group} checked={selectedRows.includes(group)} onChange={handleChange} />
      </td>
      <td>
        <span>{group}</span>
      </td>
      {!isAB && (
        <td title={description || undefined} data-wider={!isAB} data-align="start">
          <p>{description || '-'}</p>
        </td>
      )}
      {isAB &&
        Object.keys(entry).map((key) => {
          const { type, configValue } = entry[key];
          if (type === ExperimentObjectiveSessionType.PLAIN) {
            return (
              <td key={key}>
                <p>{configValue.toString()}</p>
              </td>
            );
          }

          const items = Object.keys(configValue).map((session) => {
            return <p key={`${key}_${session}`}>{`${session}: ${configValue[session].value.toString()}`}</p>;
          });

          return <td key={key}>{items}</td>;
        })}
      {values}
    </tr>
  );
}
