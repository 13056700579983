import React from 'react';
import { useSelector } from 'react-redux';

import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';
import { DirectConfigState } from '@domain/enums/directConfig/DirectConfigState';
import { RunningFormContainer } from '@pages/directConfigDetails/components/summary/config/runningForm/RunningFormContainer';
import { InDevFormContainer } from '@pages/directConfigDetails/components/summary/config/inDevForm/InDevFormContainer';

export function DirectConfigFormContainer() {
  const { state } = useSelector(directConfigDetailsSelectors.getDirectConfig);

  const isInDev = state === DirectConfigState.IN_DEV;

  if (isInDev) {
    return <InDevFormContainer />;
  }

  return <RunningFormContainer />;
}
