import React from 'react';
import cn from 'classnames';

import { RequestStage } from '@infrastructure/store/types/actions';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { DirectConfigHeader } from '@pages/directConfigList/table/DirectConfigHeader';
import { DirectConfigSkeletonRow } from '@pages/directConfigList/table/DirectConfigSkeletonRow';
import { DirectConfigRow } from '@pages/directConfigList/table/DirectConfigRow';

import baseStyles from '@components/layout/table/Table.module.scss';
import styles from '@pages/directConfigList/table/DirectConfigTable.module.scss';

type Props = {
  list: IDirectConfig[];
  status: RequestStage;
};

export function DirectConfigTable({ list, status }: Props) {
  const isFetching = status === RequestStage.REQUEST;
  const rows = list.map((config) => <DirectConfigRow key={`dc_${config.id}`} config={config} />);

  return (
    <table className={cn(baseStyles.table, styles.table)}>
      <thead>
        <DirectConfigHeader />
      </thead>
      <tbody>
        {rows}
        {isFetching && (
          <>
            <DirectConfigSkeletonRow />
            <DirectConfigSkeletonRow />
            <DirectConfigSkeletonRow />
          </>
        )}
      </tbody>
    </table>
  );
}
