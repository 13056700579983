import { Service } from 'typedi';

type LocalStorageRes = string | null;

export enum LocalStorageKey {
  ACCESS_TOKEN = 'accessToken',
  ICON_PATH = 'icon',
  REPORT_COLUMNS = 'report_cols',
  EXPERIMENT_FILTERS = 'experiment_filters',
  DIRECT_CONFIG_FILTERS = 'direct_config_filters',
  APPLIED_CONFIG_FILTERS = 'ap_filters'
}

@Service()
export class LocalStorageRepo {
  findOne(key: string): LocalStorageRes {
    return window.localStorage.getItem(key);
  }

  upsertOne(key: string, payload: string): void {
    return window.localStorage.setItem(key, payload);
  }

  deleteOne(key: string): void {
    return window.localStorage.removeItem(key);
  }
}
