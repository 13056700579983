/** @see BusinessLine */
// where BusinessLine is defined for client usage only & doesn't have a corresponding backend enum
// and BusinessUnit is defined for both client and backend usage
export enum BusinessUnit {
  CASUAL = 'CASUAL',
  HYPER_CASUAL = 'HYPER_CASUAL'
}

export const BusinessUnitLabels: { [key in BusinessUnit]: string } = {
  [BusinessUnit.CASUAL]: 'Casual',
  [BusinessUnit.HYPER_CASUAL]: 'Hyper Casual'
};
