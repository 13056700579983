import { combineReducers, createReducer } from '@reduxjs/toolkit';

import { clearAll, fetchGameConfigParams } from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { GLDParamDto } from '@domain/enums/GLDParamDto';

const gldInitial: GLDParamDto[] = [];

export const gameConfigDataReducer = createReducer(gldInitial, (qb) => {
  qb.addCase(fetchGameConfigParams.success, (state, { payload }) => {
    return payload || [];
  });
  qb.addCase(clearAll, () => {
    return gldInitial;
  });
});

export const gameConfigReducer = combineReducers({
  status: ReducerFactory.makeStatus([fetchGameConfigParams], clearAll),
  data: gameConfigDataReducer
});

export const paramsReducer = combineReducers({
  gameConfig: gameConfigReducer
});
