import { combineReducers, createReducer } from '@reduxjs/toolkit';

import { ControlGroupByCountry } from '@domain/models/ControlGroup';
import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { fetchControlGroups, clearAll } from '@infrastructure/store/directConfigDetails/directConfigDetailsActions';

const controlGroupsInitial: ControlGroupByCountry = {};

const controlGroupsDataReducer = createReducer(controlGroupsInitial, (qb) => {
  qb.addCase(fetchControlGroups.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(clearAll, () => {
    return controlGroupsInitial;
  });
});

export const controlGroupsReducer = combineReducers({
  status: ReducerFactory.makeStatus([fetchControlGroups], clearAll),
  data: controlGroupsDataReducer
});
