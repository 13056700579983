import { IsArray, IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';

export class GameConfigFormInput {
  constructor(key: string, value: string | null) {
    this.key = key;
    this.value = value;
  }

  key: string;
  value: string | null;
}

export class GameConfigForm {
  constructor(name: string, input: GameConfigFormInput[], description: string) {
    this.name = name;
    this.input = input;
    this.description = description;
  }

  name: string;
  description: string;
  input: GameConfigFormInput[];
}

export class Param {
  @IsString()
  @IsNotEmpty()
  value: string;
}

export class GameConfigObjectiveForm {
  constructor(directConfig?: IDirectConfig) {
    if (!directConfig) {
      return this;
    }
    this.defaultValue = '';
    this.params = Object.keys(directConfig.genericConfigList[0].entry).map((key) => ({ value: key }));
    this.config = directConfig.genericConfigList.map((config) => {
      return {
        name: config.name,
        description: config.description,
        input: Object.entries(config.entry).map(
          ([key, value]) => new GameConfigFormInput(key, value.configValue.value as string | null)
        )
      } as GameConfigForm;
    });
  }

  @IsString()
  defaultValue: string = '';

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Param)
  params: Param[] = [];

  @IsArray()
  config: GameConfigForm[] = [
    {
      name: 'A',
      description: 'Control Group',
      input: []
    }
  ];
}
