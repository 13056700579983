import React from 'react';
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { UserProperty } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';
import { RunningFormParams } from '@pages/directConfigDetails/components/summary/config/runningForm/types/RunningFormParams';
import { UserPropertiesContainer } from '@pages/createExperiment/targetConfig/userProperties/UserPropertiesContainer';
import { AppVersionInput } from '@pages/directConfigDetails/components/summary/config/runningForm/sections/definition/inputs/AppVersionInput';

import styles from '@pages/experimentDetails/ExperimentDetails.module.scss';

type Props = {
  control: Control<RunningFormParams>;
  register: UseFormRegister<RunningFormParams>;
  errors: FieldErrors<RunningFormParams>;
  watch: UseFormWatch<RunningFormParams>;
  setValue: UseFormSetValue<RunningFormParams>;
  userProperties: UserProperty[];
  operators: UserPropertyOperator[];
};

export function DirectConfigDefinitionForm({
  register,
  errors,
  control,
  watch,
  setValue,
  userProperties,
  operators
}: Props) {
  return (
    <ul className={styles.editorList}>
      <li>
        <AppVersionInput control={control} register={register} errors={errors} />
      </li>

      <li>
        <div className={styles.editorInputLabel}>
          <p>User Properties:</p>
        </div>
        <div>
          <UserPropertiesContainer<RunningFormParams>
            watch={watch}
            control={control}
            setValue={setValue}
            userProperties={userProperties}
            operators={operators}
            watchName="userProperties.inputs"
            hideLabel
          />
        </div>
      </li>
    </ul>
  );
}
