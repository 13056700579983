import React, { useEffect } from 'react';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import {
  initTargetConfig,
  pushTargetConfigForm
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { CreateDirectConfigForm } from '@domain/enums/CreateDirectConfigForm';
import { TargetConfigParams } from '@pages/createDirectConfig/targetConfig/types/TargetConfigParams';
import { Summary } from '@pages/createDirectConfig/Summary/Summary';
import { TargetConfig } from '@pages/createDirectConfig/targetConfig/TargetConfig';
import { useForm } from '@ui/hooks/form';
import { configSelectors } from '@infrastructure/store/config/configSelectors';

type Props = {
  isCompleted?: boolean;
};

export function TargetConfigContainer({ isCompleted }: Props) {
  const dispatch = useDispatch();

  const { targetConfig } = useSelector(createDirectConfigSelectors.getForm);
  const userProperties = useSelector(configSelectors.getUserProperties);
  const userPropertyOperators = useSelector(configSelectors.getUserPropertyOperators);

  const {
    register,
    watch,
    control,
    handleSubmit,
    resetField,
    reset,
    setValue,
    formState: { errors, isValid }
  } = useForm<TargetConfigParams>({
    schema: TargetConfigParams,
    defaultValues: targetConfig
  });

  useEffect(() => {
    dispatch(initTargetConfig.trigger());
  }, [dispatch]);

  useEffect(() => {
    !isCompleted && reset(targetConfig);
  }, [reset, targetConfig, isCompleted]);

  const handleNext = handleSubmit((form) => {
    dispatch(pushTargetConfigForm(form));
  });

  if (isCompleted) {
    return <Summary step={CreateDirectConfigForm.TARGET_CONFIG} />;
  }

  return (
    <TargetConfig
      errors={errors}
      handleSubmit={handleNext}
      register={register}
      watch={watch}
      control={control}
      resetField={resetField}
      isValid={isValid}
      setValue={setValue}
      userProperties={userProperties}
      operators={userPropertyOperators}
    />
  );
}
