import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { Platform } from '@pages/experimentDetails/atoms/labels/Platform';
import { Type } from '@pages/experimentDetails/atoms/labels/Type';
import { Dates } from '@pages/experimentDetails/atoms/labels/Dates';
import { FirebaseId } from '@pages/experimentDetails/atoms/labels/FirebaseId';
import { CreatedBy } from '@pages/experimentDetails/atoms/labels/CreatedBy';

import { ReactComponent as ArrowsFoldSvg } from '@assets/svg/arrows_fold.svg';
import { ReactComponent as ArrowsSvg } from '@assets/svg/arrows.svg';

import styles from './Summary.module.scss';
import { DirectConfigTypeLabels } from '@domain/enums/directConfig/DirectConfigType';

type Props = {
  directConfig: IDirectConfig;
  isActive: boolean;
  handleToggle: () => void;
};

// todo [Tech-debt] [CU-1642] generalize & share between experiment & direct config
export function Info({ directConfig, isActive, handleToggle }: Props) {
  const { type, stateStartDate, endDate, createdBy, game } = directConfig;
  const { firebaseProjectName, platform, firebaseProjectId } = game;

  return (
    <div className={styles.info}>
      <h5>SUMMARY</h5>
      <Platform platform={platform} />
      <Type label={DirectConfigTypeLabels[type]} />
      <Dates startDate={stateStartDate} endDate={endDate} />
      <FirebaseId firebaseName={firebaseProjectName} firebaseId={firebaseProjectId} />
      <CreatedBy createdBy={createdBy} />
      <Button name="summary-toggler" variant={ButtonVariant.TERTIARY} onClick={handleToggle}>
        {isActive ? <ArrowsFoldSvg /> : <ArrowsSvg />}
      </Button>
    </div>
  );
}
