import capitalizeString from '@infrastructure/utils/libs/capitalizeString';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ExpTypeTitleMap } from '@ui/const/ExpTypeTitleMap';

/** @deprecated */
export function getTitleFromKey(key) {
  const withSpaces = key.replace(/_/gi, ' ');
  const words = withSpaces.split(' ');

  const capitalWords = words.map((word) => capitalizeString(word));

  return capitalWords.join(' ');
}

/** @deprecated */
export function getTitleForExperimentType(key) {
  if (!key) {
    return '-';
  }

  if (key === ExperimentType.AB_TESTING) {
    return ExpTypeTitleMap[ExperimentType.AB_TESTING];
  }

  return getTitleFromKey(key);
}

/** @deprecated */
export function getTitleForPlatforms(key) {
  if (!key) {
    return '-';
  }

  if (key === GamePlatform.IOS) {
    return 'App Store';
  }

  if (key === GamePlatform.ANDROID) {
    return 'Google Play';
  }

  if (key === 'ALL_PLATFORM') {
    return 'All Platforms';
  }

  return getTitleFromKey(key);
}
