import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Routing } from '@infrastructure/routing';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { PlatformIcon } from '@components/layout/platformIcon/PlatformIcon';
import { TableCellType } from '@components/layout/table/types/TableCellType';
import { Image } from '@components/layout/Image';
import { ResourceMapper } from '@app/mappers/ResourceMapper';
import { CountryLabel } from '@components/layout/countryLabel/CountryLabel';
import { DirectConfigStatus } from '@components/layout/directConfigStatus/DirectConfigStatus';
import { DirectConfigTypeLabels } from '@domain/enums/directConfig/DirectConfigType';
import { DateLabel } from '@components/layout/dateLabel/DateLabel';

type Props = {
  config: IDirectConfig;
};

export function DirectConfigRow({ config }: Props) {
  const navLinkRef = useRef<HTMLAnchorElement>(null);

  const handleNavClick = useCallback((e) => {
    if (navLinkRef.current) {
      navLinkRef.current.click();
    }
  }, []);

  const { id, name, type, state, game, endDate, stateStartDate, liveRegions } = config;

  const { gameName, iconUrl, platform } = game;

  const iconUrlFormatted = ResourceMapper.mapS3UrlToCDN(iconUrl);
  const regionList = liveRegions.map((region) => region.countryCodes[0] || region.name);

  return (
    <tr data-id={id} onClick={handleNavClick}>
      <td data-cell={TableCellType.ICON}>
        <Image src={iconUrlFormatted} alt={`${gameName} icon`} />
      </td>
      <td data-cell={TableCellType.TITLE}>{gameName}</td>
      <td data-cell={TableCellType.TITLE}>
        <Link to={Routing.getDirectConfig(id)} ref={navLinkRef}>
          {name}
        </Link>
      </td>
      <td data-cell={TableCellType.ICON}>
        <PlatformIcon platform={platform} />
      </td>
      <td data-cell={TableCellType.TEXT}>{DirectConfigTypeLabels[type]}</td>
      <td data-cell={TableCellType.LABEL}>
        <DirectConfigStatus status={state} />
      </td>
      <td data-cell={TableCellType.DATE}>
        <DateLabel date={stateStartDate} />
        <br />
        <DateLabel date={endDate} fallbackText="Present" />
      </td>
      <td data-cell={TableCellType.LABEL}>{regionList.length !== 0 && <CountryLabel countryCode={regionList} />}</td>
    </tr>
  );
}
