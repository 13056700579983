import { subMonths } from 'date-fns';
import { GamePlatform } from '@domain/enums/GamePlatform';
import { DefaultFormValue } from '@ui/hooks/form';
import { DirectConfigState } from '@domain/enums/directConfig/DirectConfigState';
import { DirectConfigsRequestParams } from '@domain/models/directConfig/DirectConfigsRequestParams';
import { DateUtils } from '@app/utils/DateUtils';
import { BusinessUnit } from '@domain/enums/directConfig/BusinessUnit';

export class DirectConfigFiltersForm {
  constructor(body?: Partial<DirectConfigFiltersForm>) {
    if (body && Object.keys(body).length) {
      Object.keys(body).forEach((key) => {
        this[key] = body[key];
      });
    }

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    if (!body?.dateFrom) {
      this.dateFrom = subMonths(now, 3);
    }
    if (!body?.dateTo) {
      this.dateTo = now;
    }
  }

  name: string = '';

  states: (DirectConfigState | DefaultFormValue)[] = [];

  businessUnit: BusinessUnit | DefaultFormValue = DefaultFormValue.ALL;

  gamePlatform: GamePlatform | DefaultFormValue = DefaultFormValue.ALL;

  dateTo: Date;

  dateFrom: Date;

  public toRequestParams(): DirectConfigsRequestParams {
    return {
      name: this.name || undefined,
      states: this.states.includes(DefaultFormValue.ALL) ? undefined : (this.states as DirectConfigState[]),
      businessUnit: this.businessUnit === DefaultFormValue.ALL ? undefined : this.businessUnit,
      gamePlatform: this.gamePlatform === DefaultFormValue.ALL ? undefined : this.gamePlatform,
      dateFrom: DateUtils.formatToDateISODate(this.dateFrom),
      dateTo: DateUtils.formatToDateISODate(this.dateTo)
    };
  }
}
