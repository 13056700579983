import React from 'react';
import { Control, UseFormRegister, UseFormResetField, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import cn from 'classnames';

import { UserProperty } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { UserPropertiesContainer } from '@pages/createExperiment/targetConfig/userProperties/UserPropertiesContainer';
import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';
import { LiveRegionsInput } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/targetingConfig/inputs/LiveRegionsInput';
import { InDevRegionsInput } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/targetingConfig/inputs/InDevRegionsInput';
import { VersionsInput } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/targetingConfig/inputs/VersionsInput';
import { GameStatsContainer } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/targetingConfig/GameStatsContainer';

import styles from '../../InDevForm.module.scss';
import { InDevFormParams } from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';
import { InputLabel } from 'crazy-ui-next';

type Props = {
  register: UseFormRegister<InDevFormParams>;
  setValue: UseFormSetValue<InDevFormParams>;
  watch: UseFormWatch<InDevFormParams>;
  control: Control<InDevFormParams>;
  resetField: UseFormResetField<InDevFormParams>;
  userProperties: UserProperty[];
  operators: UserPropertyOperator[];
};

export function TargetingConfigSection({
  watch,
  control,
  register,
  resetField,
  setValue,
  userProperties,
  operators
}: Props) {
  return (
    <div>
      <div className={styles.gameStats}>
        <GameStatsContainer />
      </div>
      <ul className={cn(styles.formSection, styles.devPhase)}>
        <LiveRegionsInput control={control} resetField={resetField} />
        <InDevRegionsInput control={control} resetField={resetField} />
        <VersionsInput register={register} control={control} />

        <li>
          <InputLabel labelText="User Properties:" className={styles.editorInputLabel} />
          <div>
            <UserPropertiesContainer<InDevFormParams>
              watch={watch}
              control={control}
              setValue={setValue}
              userProperties={userProperties}
              operators={operators}
              watchName="userProperties.inputs"
              hideLabel
            />
          </div>
        </li>
      </ul>
    </div>
  );
}
