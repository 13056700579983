import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { setFilters } from '@infrastructure/store/directConfigList/directConfigListActions';
import { LocalStorageKey } from '@infrastructure/repositories/LocalStorageRepo';
import { filterMigration } from '@infrastructure/store/directConfigList/migrations/filterMigration';
import { DirectConfigFiltersForm } from '@pages/directConfigList/filters/types/DirectConfigFiltersForm';

const filtersInitial = new DirectConfigFiltersForm();

const filtersReducer = createReducer(filtersInitial, (qb) => {
  qb.addCase(setFilters, (state, { payload }) => {
    return payload;
  });
});

export const filterCacheReducer = persistReducer(
  {
    key: LocalStorageKey.DIRECT_CONFIG_FILTERS,
    whitelist: ['name', 'states', 'businessUnit', 'gamePlatform'],
    version: 1,
    migrate: filterMigration,
    storage
  },
  filtersReducer
);
