import { call, put, takeLatest } from 'redux-saga/effects';
import { Service } from 'typedi';

import { GameService } from '@app/services/GameService';
import { RegionService } from '@app/services/RegionService';

import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { BaseSagaWatcher } from '@infrastructure/sagas/BaseSagaWatcher';
import { Notification } from '@infrastructure/api/notifications';
import {
  initDirectConfigDetails,
  fetchDirectConfig,
  fetchDefaultRegions,
  fetchGameStats,
  fetchControlGroups,
  pushInDevUpdate,
  pushRunningUpdate,
  pushStateUpdate
} from '@infrastructure/store/directConfigDetails/directConfigDetailsActions';
import { fetchUserProperties } from '@infrastructure/store/config/configActions';
import { UserPropertiesDto } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { DirectConfigDetailsService } from '@app/services/DirectConfigDetailsService';

@Service()
export class DirectConfigDetailsSaga extends BaseSagaWatcher {
  constructor(
    private service: DirectConfigDetailsService,
    private gameService: GameService,
    private regionService: RegionService,
    rootWatcher: RootSagaWatcher
  ) {
    super(rootWatcher);
  }

  public getEffects() {
    return [
      takeLatest(initDirectConfigDetails.trigger, this.initDirectConfigDetails.bind(this)),
      takeLatest(initDirectConfigDetails.success, this.fetchDefaultRegions.bind(this)),
      takeLatest(initDirectConfigDetails.success, this.fetchGameStats.bind(this)),
      takeLatest(initDirectConfigDetails.success, this.fetchControlGroups.bind(this)),
      takeLatest(initDirectConfigDetails.success, this.fetchUserProperties.bind(this)),

      takeLatest(fetchDirectConfig.trigger, this.fetchDirectConfig.bind(this)),

      takeLatest(pushInDevUpdate.trigger, this.pushInDevUpdate.bind(this)),
      takeLatest(pushRunningUpdate.trigger, this.pushRunningUpdate.bind(this)),
      takeLatest(pushStateUpdate.trigger, this.pushStateUpdate.bind(this))
    ];
  }

  public *initDirectConfigDetails(action: ReturnType<typeof initDirectConfigDetails.trigger>) {
    try {
      yield put(initDirectConfigDetails.request());

      const result = yield call([this.service, this.service.get], action.payload);

      yield put(initDirectConfigDetails.success(result));
    } catch (err) {
      Notification.error(err);
      yield put(initDirectConfigDetails.failure());
    }
  }

  public *fetchDirectConfig(action: ReturnType<typeof fetchDirectConfig.trigger>) {
    try {
      yield put(fetchDirectConfig.request());

      const result = yield call([this.service, this.service.get], action.payload);

      yield put(fetchDirectConfig.success(result));
    } catch (err) {
      Notification.error(err);
      yield put(fetchDirectConfig.failure());
    }
  }

  public *fetchUserProperties(action: ReturnType<typeof initDirectConfigDetails.success>) {
    try {
      yield put(fetchUserProperties.request());

      const result: UserPropertiesDto = yield call([this.service, this.service.fetchUserProperties], action.payload);

      yield put(fetchUserProperties.success(result));
    } catch (err) {
      yield put(fetchUserProperties.failure());
      Notification.error(err);
    }
  }

  public *fetchDefaultRegions() {
    try {
      yield put(fetchDefaultRegions.request());

      const defaultRegions = yield call([this.regionService, this.regionService.getDefaultRegions]);

      yield put(fetchDefaultRegions.success(defaultRegions));
    } catch (err) {
      Notification.error(err);
      yield put(fetchDefaultRegions.failure());
    }
  }

  public *fetchGameStats(action: ReturnType<typeof initDirectConfigDetails.success>) {
    try {
      yield put(fetchGameStats.request());

      const { game } = action.payload;
      const result = yield call([this.gameService, this.gameService.fetchGameStats], game.id);

      yield put(fetchGameStats.success(result));
    } catch (err) {
      yield put(fetchGameStats.failure());
      Notification.warning('Failed to fetch game stats');
    }
  }

  public *fetchControlGroups(action: ReturnType<typeof initDirectConfigDetails.success>) {
    try {
      yield put(fetchControlGroups.request());

      const { game } = action.payload;
      const result = yield call([this.service, this.service.fetchControlGroups], game.id);

      yield put(fetchControlGroups.success(result));
    } catch (err) {
      yield put(fetchControlGroups.failure());
      Notification.warning('Failed to fetch control group from appsdb');
    }
  }

  public *pushInDevUpdate(action: ReturnType<typeof pushInDevUpdate.trigger>) {
    try {
      yield put(pushInDevUpdate.request());

      const result = yield call([this.service, this.service.updateInDev], action.payload);

      yield put(pushInDevUpdate.success(result));
      Notification.success('Targeting Segment has been successfully updated');
    } catch (err) {
      Notification.error(err);
      yield put(pushInDevUpdate.failure());
    }
  }

  public *pushRunningUpdate(action: ReturnType<typeof pushRunningUpdate.trigger>) {
    try {
      yield put(pushRunningUpdate.request());

      const result = yield call([this.service, this.service.updateRunning], action.payload);

      yield put(pushRunningUpdate.success(result));
      Notification.success('Targeting Segment has been successfully updated');
    } catch (err) {
      Notification.error(err);
      yield put(pushRunningUpdate.failure());
    }
  }

  public *pushStateUpdate(action: ReturnType<typeof pushStateUpdate.trigger>) {
    try {
      yield put(pushStateUpdate.request());

      const result = yield call([this.service, this.service.stateUpdate], action.payload);

      yield put(pushStateUpdate.success(result));
      Notification.success('Targeting Segment has been successfully updated');
    } catch (err) {
      Notification.error(err);
      yield put(pushStateUpdate.failure());
    }
  }
}
