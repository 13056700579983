import React, { useCallback, useEffect, useState } from 'react';
import { useController, UseFormWatch } from 'react-hook-form';
import { Button, ButtonVariant } from 'crazy-ui-next';
import { IsNotEmpty, IsString } from 'class-validator';
import cn from 'classnames';
import { Tooltip } from 'crazy-ui';

import { GameConfigObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';
import { ReactComponent as EditSvg } from '@assets/svg/edit.svg';
import { ReactComponent as DeleteSvg } from '@assets/svg/trash.svg';
import { useForm } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/InputGroup.module.scss';

type Props = {
  watch: UseFormWatch<GameConfigObjectiveForm>;
  param: string;
  paramIndex: number;
  handleRemoveParam: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleUpdateParam: (newParam: string, index: number) => void;
};

class Param {
  @IsNotEmpty()
  @IsString()
  value: string;
}

export function EditableParamName({ watch, paramIndex, param, handleRemoveParam, handleUpdateParam }: Props) {
  const params = watch('params');
  const formattedParams = params.filter(({ value }) => value !== param).map(({ value }) => value.toLowerCase());
  const [isEdit, setIsEdit] = useState(false);

  const {
    control,
    handleSubmit,
    setFocus,
    setError,
    formState: { errors }
  } = useForm<Param>({ schema: Param, defaultValues: { value: param } });
  const { field } = useController({ control, name: 'value' });

  const handleEdit = useCallback(() => {
    setIsEdit(true);
  }, [setIsEdit]);

  const handleSave = handleSubmit((body) => {
    if (formattedParams.includes(body.value.toLowerCase())) {
      setError('value', {
        type: 'custom',
        message: 'Param name should be uniq'
      });
      return;
    }

    handleUpdateParam(body.value, paramIndex);
  });

  const handleBlur = useCallback(() => {
    handleSave();
    setIsEdit(false);
  }, [handleSave, setIsEdit]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (['Enter', 'Escape'].includes(event.key)) {
        handleSave();
        setIsEdit(false);
      }
    },
    [handleSave, setIsEdit]
  );

  useEffect(() => {
    if (isEdit) {
      setFocus('value');
    }
  }, [isEdit, setFocus]);

  return (
    <th className={cn(styles.editableCell, errors.value?.message && styles.error)} data-edit={isEdit}>
      <Tooltip text={errors.value?.message} delay={200}>
        <>
          {isEdit ? (
            <input
              type="text"
              value={field.value}
              ref={field.ref}
              onChange={field.onChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <span>{field.value}</span>
          )}
          <div className={styles.controls}>
            <Button variant={ButtonVariant.TERTIARY} onClick={handleEdit} icon={<EditSvg />} />
            <Button
              variant={ButtonVariant.TERTIARY}
              data-index={paramIndex}
              data-value={param}
              onClick={handleRemoveParam}
              icon={<DeleteSvg />}
            />
          </div>
        </>
      </Tooltip>
    </th>
  );
}
