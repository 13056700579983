import { UpdateWinnerConfigParams, WinnerInput } from '@domain/models/winnerConfig/WinnerConfigFormParams';
import { ApplyConfigRequest } from '@domain/models/winnerConfig/ApplyConfigRequest';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { UniqueId } from '@domain/types';
import { VersionInput } from '@domain/models/VersionInput';
import { VersionOperator } from '@domain/enums/VersionOperator';

export class WinnerConfigMapper {
  static mapFieldsToFormParams(objective: ExperimentObjectiveDto, experimentId: UniqueId): UpdateWinnerConfigParams {
    const body = new UpdateWinnerConfigParams();

    const { winnerInfo } = objective;

    const version = winnerInfo?.version || objective.appVersions;
    body.versionOperator = winnerInfo?.versionOperator || VersionOperator.EQUAL;

    body.version = version.map((value) => {
      const input = new VersionInput();

      input.value = value;

      return input;
    });

    body.experimentId = experimentId;
    body.objectiveId = objective.id;

    const winners: WinnerInput[] = [];

    objective.regions.forEach((regionDto) => {
      const input = new WinnerInput();

      input.group = objective.configs[0].name;
      input.regionName = regionDto.name;
      input.regionCode = regionDto.countryCodes.length ? regionDto.countryCodes[0] : regionDto.name;

      input.applied = false;

      if (winnerInfo) {
        const { regionalConfigs } = winnerInfo;
        const regionalConfig = regionalConfigs.find(({ region }) => region === regionDto.name);

        if (regionalConfig?.group) {
          input.group = regionalConfig.group;
          input.applied = true;
        }
      }

      winners.push(input);
    });

    body.winners = winners;
    body.regions = objective.regions;

    return body;
  }

  static mapFormParamsToRequest(body: UpdateWinnerConfigParams): ApplyConfigRequest {
    const dto = new ApplyConfigRequest();

    const { winners } = body;

    const regionsPerGroup: Record<number, string> = {};

    winners
      .filter((winner) => winner.applied)
      .forEach((winner) => {
        const winnerRegion = body.regions.find((region) => region.name === winner.regionName);

        if (winnerRegion) {
          regionsPerGroup[winnerRegion.id] = winner.group;
        }
      });

    dto.regionsPerGroup = regionsPerGroup;
    dto.version = body.version.map(({ value }) => value);
    dto.versionOperator = body.versionOperator;

    return dto;
  }
}
