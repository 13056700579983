import React, { useEffect } from 'react';

import { DirectConfigListPage } from '@pages/directConfigList/DirectConfigListPage';
import { useDispatch } from '@ui/hooks/redux';
import { clearAll, fetchDirectConfigs } from '@infrastructure/store/directConfigList/directConfigListActions';

export function DirectConfigListPageContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDirectConfigs.trigger());

    return () => {
      dispatch(clearAll());
    };
  }, [dispatch]);

  return <DirectConfigListPage />;
}
