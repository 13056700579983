import { Dto } from '@domain/models/Dto';
import { UniqueId } from '@domain/types';

export interface UserProperty {
  id: UniqueId;
  displayName: string;
}

// todo [Tech-debt] [CU-1642] - move to more global lvl as it's not related to create experiment only
export class UserPropertiesDto extends Dto {
  userProperties: UserProperty[];
}
