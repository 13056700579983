import React, { useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { pushClone, clearAll } from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { CreateDirectConfig } from '@pages/createDirectConfig/CreateDirectConfig';
import { OverlappedConfigsContainer } from '@pages/createDirectConfig/notifications/overlappedConfigs/OverlappedConfigsContainer';
import { LiveConfigurationsContainer } from '@pages/createDirectConfig/notifications/liveConfigurations/LiveConfigurationsContainer';
import CheckPermission from '@components/layout/CheckPermissions';
import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';
import { PageLoader } from '@components/layout/Loader';

import styles from '@pages/createDirectConfig/CreateDirectConfig.module.scss';

export function CreateDirectConfigContainer() {
  const dispatch = useDispatch();
  const cloneStatus = useSelector(createDirectConfigSelectors.cloneStatus);

  useEffect(() => {
    // todo [Tech-debt] [CU-1642] rework to init action, fetch direct config if available & resolve clone functionality on form lvl

    dispatch(pushClone.trigger());
    return () => {
      dispatch(clearAll());
    };
  }, [dispatch]);

  if (cloneStatus !== RequestStage.SUCCESS) {
    return <PageLoader />;
  }

  return (
    <CheckPermission admin analyst qa>
      <main className={cn(styles.main, 'container')}>
        <CreateDirectConfig />
        <section className={styles.notifications}>
          <OverlappedConfigsContainer />
          <LiveConfigurationsContainer />
        </section>
      </main>
    </CheckPermission>
  );
}
