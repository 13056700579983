import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { TextInput } from 'crazy-ui-next';

import { getInputProps } from '@ui/hooks/form';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';

import styles from '@pages/createExperiment/targetConfig/TargetingConfig.module.scss';

type Props = {
  register: UseFormRegister<TargetConfigParams>;
};

export function ImportedSegmentsInput({ register }: Props) {
  return (
    <TextInput
      labelText="Imported segments"
      placeholder="Imported segments"
      className={styles.input}
      {...getInputProps<TargetConfigParams>('importedSegments', register, {})}
    />
  );
}
