import React from 'react';

import CheckPermission from '@components/layout/CheckPermissions';
import { DateRangePicker } from '@components/lib/DateRangePicker/DateRangePicker';
import { StatusFilter } from '@pages/experimentList/filters/StatusFilter';
import { BusinessLinesFilter } from './BusinessLinesFilter';
import { PlatformFilter } from '@pages/experimentList/filters/PlatformFilter';
import { TypeFilter } from '@pages/experimentList/filters/TypeFilter';
import { SearchFilter } from '@pages/experimentList/filters/SearchFilter';

export default function ExperimentFilter({
  handleDateRange,
  handleSearch,
  handleStatuses,
  handleType,
  handlePlatform,
  searchPhrase,
  type,
  platform,
  statuses,
  startDate,
  endDate,
  handleBusinessLines,
  businessLines
}) {
  return (
    <section className="my-4">
      <div className="row">
        <div className="col-4">
          <SearchFilter value={searchPhrase} handleChange={handleSearch} />
        </div>
        <div className="col-3 d-flex justify-content-end">
          <DateRangePicker
            name="experimentDate"
            startDate={startDate}
            endDate={endDate}
            handleChange={handleDateRange}
            labelText="Start Date"
          />
        </div>
        <div className="col-2">
          <BusinessLinesFilter selected={businessLines} onChange={handleBusinessLines} />
        </div>
        <div className="col-2">
          <PlatformFilter selected={platform} onChange={handlePlatform} />
        </div>
        <CheckPermission admin analyst owner>
          <div className="col-2">
            <StatusFilter selected={statuses} onChange={handleStatuses} />
          </div>
        </CheckPermission>
        <CheckPermission admin analyst owner>
          <div className="col-3">
            <TypeFilter selected={type} onChange={handleType} />
          </div>
        </CheckPermission>
      </div>
    </section>
  );
}
