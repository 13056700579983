import { createReducer } from '@reduxjs/toolkit';

import {
  clearAll,
  pushSetStep,
  fetchLiveConfigs
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { steps } from '@pages/createDirectConfig/steps/const/Steps';
import { ILiveConfig } from '@domain/models/experiment/LiveConfig';

const initial: ILiveConfig[] = [];

export const liveConfigsReducer = createReducer(initial, (qb) => {
  qb.addCase(fetchLiveConfigs.success, (state, { payload }) => {
    return payload;
  });
  qb.addCase(pushSetStep, (state, { payload: step }) => {
    if (step <= steps[0].index) {
      return initial;
    }

    return state;
  });
  qb.addCase(clearAll, () => {
    return initial;
  });
});
