import React, { useCallback } from 'react';
import { memoize } from 'proxy-memoize';

import { useToggle } from '@ui/hooks/useToggle';

import { DirectConfigDefinitionSummary } from '@pages/directConfigDetails/components/summary/config/runningForm/sections/definition/DirectConfigDefinitionSummary';
import { DirectConfigDefinitionForm } from '@pages/directConfigDetails/components/summary/config/runningForm/sections/definition/DirectConfigDefinitionForm';
import { FormControls } from '@pages/directConfigDetails/components/summary/config/atoms/FormControls';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { useForm } from '@ui/hooks/form';
import { pushRunningUpdate } from '@infrastructure/store/directConfigDetails/directConfigDetailsActions';
import { RequestStage } from '@infrastructure/store/types/actions';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';
import { RunningFormParams } from '@pages/directConfigDetails/components/summary/config/runningForm/types/RunningFormParams';
import { cloneDeep } from 'lodash-es';
import { DirectConfigState } from '@domain/enums/directConfig/DirectConfigState';

export function DirectConfigDefinitionContainer() {
  const dispatch = useDispatch();

  const { isActive: editorEnabled, handleOpen: openEditor, handleClose: closeEditor } = useToggle(false);

  const directConfig = useSelector(memoize(directConfigDetailsSelectors.getDirectConfig));
  const initForm = new RunningFormParams(directConfig);
  const form = cloneDeep(initForm);
  const { definitionUpdate: status } = useSelector(experimentDetailsSelectors.getSummaryEditorStatus);
  const userProperties = useSelector(configSelectors.getUserProperties);
  const userPropertyOperators = useSelector(configSelectors.getUserPropertyOperators);

  const isLoading = status === RequestStage.REQUEST;

  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    setValue,
    reset
  } = useForm<RunningFormParams>({
    schema: RunningFormParams,
    defaultValues: form
  });

  const handleFormSubmit = handleSubmit((params) => {
    dispatch(pushRunningUpdate.trigger(params));
    closeEditor();
  });

  const handleClose = useCallback(() => {
    closeEditor();
    reset(form);
  }, [closeEditor, form, reset]);

  const isEditDisabled = directConfig.state !== DirectConfigState.LIVE;

  return (
    <>
      {editorEnabled ? (
        <DirectConfigDefinitionForm
          control={control}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          userProperties={userProperties}
          operators={userPropertyOperators}
        />
      ) : (
        <DirectConfigDefinitionSummary />
      )}
      <FormControls
        openEditor={openEditor}
        closeEditor={handleClose}
        editorEnabled={editorEnabled}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
        isSubmitDisabled={!isValid}
        isEditDisabled={isEditDisabled}
      />
    </>
  );
}
