import { IsArray, ArrayNotEmpty } from 'class-validator';
import { merge } from 'lodash-es';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';

export type ControlGroupForm = Record<string, string>;

export class AdProfileObjectiveForm {
  constructor(directConfigForm?: IDirectConfig) {
    if (!directConfigForm) {
      return this;
    }

    this.params = Object.keys(directConfigForm.genericConfigList[0].entry).map((key) => key);
  }

  @IsArray()
  @ArrayNotEmpty()
  params: string[] = [];

  controlGroup: ControlGroupForm;

  static ofInitial() {
    const form = new AdProfileObjectiveForm();
    form.params = [];
    form.controlGroup = {};
    return form;
  }

  public setControlGroup(controlGroups: ControlGroupForm) {
    this.controlGroup = controlGroups;
    return this;
  }

  public mergeControlGroup(controlGroup: ControlGroupForm) {
    this.controlGroup = merge(controlGroup, this.controlGroup);
    return this;
  }
}
