import React from 'react';

import { Button, ModalWrapper } from 'crazy-ui';

type Props = {
  handleModal: () => void;
  handleConfirm: () => void;
  title: string;
  subtitle: string;
};

export function ConfirmModal({ handleModal, handleConfirm, title, subtitle }: Props) {
  return (
    <ModalWrapper title={title} subtitle={subtitle} handleModal={handleModal}>
      <div className="m-btn-list">
        <Button variant={Button.OUTLINE} color={Button.GRAY} withMargin={false} onClick={handleModal} className="m-btn">
          Cancel
        </Button>
        <Button variant={Button.Fill} color={Button.BLUE} withMargin={false} onClick={handleConfirm} className="m-btn">
          Confirm
        </Button>
      </div>
    </ModalWrapper>
  );
}
