import { AppState } from '@infrastructure/store';
import { RequestStage } from '@infrastructure/store/types/actions';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { RegionDto } from '@domain/models/RegionDto';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';

const getDirectConfigStatus = (state: AppState): RequestStage => state.directConfigDetails.directConfig.status;
const getDirectConfig = (state: AppState): IDirectConfig => state.directConfigDetails.directConfig.data;

const getGameStats = (state: AppState): GameStatsDto[] => state.directConfigDetails.gameStats.data;
const getControlGroups = (state: AppState) => state.directConfigDetails.controlGroups.data;
const getDefaultRegions = (state: AppState): RegionDto[] => state.directConfigDetails.defaultRegions.data;
const getDefaultRegionsStatus = (state: AppState): RequestStage => state.directConfigDetails.defaultRegions.status;

const getGameApp = (state: AppState) => {
  const { game } = getDirectConfig(state);

  return game;
};

const getGameStatsByPlatform = (state: AppState) => {
  const stats = getGameStats(state);
  const { platform } = getGameApp(state);

  return stats.filter((stat) => stat.platform === platform);
};
const getGameStatsFilter = (state: AppState) => state.directConfigDetails.gameStats.filter;

const getActionsStatus = (state: AppState): RequestStage => state.directConfigDetails.actionsStatus;

export const directConfigDetailsSelectors = {
  getDirectConfigStatus,
  getDirectConfig,
  getGameStats,
  getControlGroups,
  getDefaultRegions,
  getDefaultRegionsStatus,
  getGameStatsByPlatform,
  getGameStatsFilter,
  getActionsStatus
};
