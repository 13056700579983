import { ArrayNotEmpty, IsArray, IsEnum, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

import { FormSummary } from '@pages/createExperiment/atoms/Summary/Summary';
import { ExperimentRegion, ExperimentRegionName } from '@domain/enums/ExperimentRegion';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { VersionInput } from '@domain/models/VersionInput';
import { UserPropertiesParams } from '@domain/models/createExperiment/userProperties/UserPropertiesParams';

export class RegionOption {
  value: string;
  label: string;
}

export class TargetConfigParams {
  @IsEnum(ExperimentRegion)
  @IsNotEmpty()
  regionType: string;

  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => RegionOption)
  regions: RegionOption[];

  @IsString()
  @IsNotEmpty()
  primaryRegion: string;

  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => RegionOption)
  regionsForInDev: RegionOption[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => VersionInput)
  versions: VersionInput[];

  @IsString()
  @IsOptional()
  importedSegments: string;

  userProperties: UserPropertiesParams;

  static ofInitial() {
    const form = new TargetConfigParams();

    form.regionType = ExperimentRegion.US;
    form.regions = [{ value: ExperimentRegionName.US, label: ExperimentRegionName.US }];
    form.primaryRegion = ExperimentRegionName.US;
    form.regionsForInDev = [{ value: ExperimentRegionName.MK, label: ExperimentRegionName.MK }];
    form.versions = [{ value: '' }];
    form.importedSegments = '';
    form.userProperties = { inputs: [] };

    return form;
  }

  public pushRegions(regionName: RegionOption) {
    this.regions.push(regionName);
    return this;
  }

  public setVersion(version: string) {
    this.versions = [{ value: version }];
    return this;
  }

  public setRegionType(regionType: ExperimentRegion) {
    this.regionType = regionType;
    return this;
  }

  public setRegions(regionNames: string[]) {
    this.regions = regionNames.map((regionName) => ({ value: regionName, label: regionName }));
    return this;
  }

  public cleanFormParams() {
    this.userProperties.inputs = this.userProperties.inputs.filter((property) => property.value.trim().length > 0);
    return this;
  }

  private getUserPropertiesLabel(userProperties: UserPropertiesParams): string {
    const { inputs } = userProperties;
    const nonEmptyValues = inputs.filter(({ value }) => value.trim().length);

    if (!nonEmptyValues.length) {
      return '-';
    }

    return nonEmptyValues
      .map(
        ({ userPropertyDisplayName, operatorDisplayName, value }) =>
          `${userPropertyDisplayName} ${operatorDisplayName} ${value}`
      )
      .join(', ');
  }

  getSummary(experimentType: ExperimentType): FormSummary {
    if (experimentType === ExperimentType.GLD_TEST) {
      return [
        { title: 'Live Regions', value: this.regions.map(({ value }) => value).join(', ') },
        { title: 'Decisioning Region', value: this.primaryRegion },
        { title: 'In Dev Regions', value: this.regionsForInDev.map(({ value }) => value).join(', ') },
        {
          title: 'Versions',
          value: this.versions.filter((x) => x.value.trim().length).length
            ? this.versions
                .filter(({ value }) => value)
                .map(({ value }) => value)
                .join(', ')
            : 'All versions'
        },
        { title: 'Imported Segments', value: this.importedSegments || '-' },
        { title: 'User Properties', value: this.getUserPropertiesLabel(this.userProperties) }
      ];
    }

    return [
      { title: 'Countries', value: this.regions.map(({ value }) => value).join(', ') },
      {
        title: 'Versions',
        value: this.versions.filter((x) => x.value.trim().length).length
          ? this.versions
              .filter(({ value }) => value)
              .map(({ value }) => value)
              .join(', ')
          : 'All versions'
      },
      { title: 'Imported Segments', value: this.importedSegments || '-' },
      { title: 'User Properties', value: this.getUserPropertiesLabel(this.userProperties) }
    ];
  }
}
