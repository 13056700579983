import React from 'react';
import { TRow } from './TRow';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';

type Props = {
  variables: string[];
  isAdProfile: boolean;
  config: GenericConfigEntry;
};

export function TBody({ variables, isAdProfile, config }: Props) {
  return (
    <tbody>
      <TRow config={config} variables={variables} isAdProfile={isAdProfile} />
    </tbody>
  );
}
