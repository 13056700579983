import React from 'react';

import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { DirectConfigFormContainer } from '@pages/directConfigDetails/components/summary/config/DirectConfigFormContainer';
import { Info } from '@pages/directConfigDetails/components/summary/Info';

import styles from './Summary.module.scss';

type Props = {
  directConfig: IDirectConfig;
  isActive: boolean;
  handleToggle: () => void;
};

export function Summary({ directConfig, isActive, handleToggle }: Props) {
  return (
    <section className={styles.wrapper}>
      <Info directConfig={directConfig} isActive={isActive} handleToggle={handleToggle} />
      {isActive && <DirectConfigFormContainer />}
    </section>
  );
}
