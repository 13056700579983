import { IsEnum, IsNotEmpty, IsInt, IsString, Matches, MaxLength } from 'class-validator';

import { GamePlatform, GamePlatformTitleMap } from '@domain/enums/GamePlatform';
import { FormSummary } from '@pages/createExperiment/atoms/Summary/Summary'; // fixme copy for direct config form
import { DirectConfigType, DirectConfigTypeLabels } from '@domain/enums/directConfig/DirectConfigType';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';

export class BasicInfoParams {
  constructor(directConfig?: IDirectConfig) {
    if (!directConfig) {
      return this;
    }

    this.type = directConfig.type;
    this.name = directConfig.name;
    this.gameName = directConfig.game.gameName;
    this.gameId = directConfig.game.id;
    this.platform = directConfig.game.platform;
    this.bundleId = directConfig.game.bundleId;
    this.firebaseProjectId = directConfig.game.firebaseProjectId;
  }

  @IsEnum(DirectConfigType)
  @IsNotEmpty()
  type: DirectConfigType = DirectConfigType.AD_PROFILE;

  @IsString()
  @IsNotEmpty()
  @Matches(/^[a-zA-Z0-9-_.\s]*$/, { message: 'Invalid name. Please use [A-Z0-9-_.]' })
  @MaxLength(256)
  name: string = '';

  @IsString()
  @IsNotEmpty()
  gameName: string = '';

  @IsInt()
  @IsNotEmpty()
  gameId: number = 0;

  @IsEnum(GamePlatform)
  @IsNotEmpty()
  platform: GamePlatform = '' as GamePlatform;

  @IsString()
  @IsNotEmpty()
  bundleId: string = '';

  @IsString()
  @IsNotEmpty()
  firebaseProjectId: string = '';

  getSummary(): FormSummary {
    return [
      { title: 'Targeting Type', value: DirectConfigTypeLabels[this.type] },
      { title: 'Targeting Name', value: this.name },
      { title: 'Game Name', value: this.gameName },
      { title: 'Platform', value: GamePlatformTitleMap[this.platform] },
      { title: 'Bundle ID', value: this.bundleId },
      { title: 'Firebase Project', value: this.firebaseProjectId }
    ];
  }
}
