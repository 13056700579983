import React from 'react';

import { TRow } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/components/adProfileSummary/atoms/TRow';
import { AdProfileObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/types/AdProfileObjectiveForm';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';

type Props = { adProfileObjective: AdProfileObjectiveForm; variables: ExperimentVariableDto[] };

export function TBody({ adProfileObjective, variables }: Props) {
  return (
    <tbody>
      <TRow adProfileObjective={adProfileObjective} variables={variables} />
    </tbody>
  );
}
