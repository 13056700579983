import React, { useCallback } from 'react';
import { useFieldArray, UseFormSetFocus, UseFormWatch } from 'react-hook-form';
import { cloneDeep } from 'lodash-es';

import { GLDParamDto } from '@domain/enums/GLDParamDto';
import { DefaultValueInput } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/inputs/DefaultValueInput';
import { Thead } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/components/inputGroup/atoms/Thead';
import { TBody } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/components/inputGroup/atoms/TBody';
import { AddParamInput } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/components/inputGroup/atoms/AddParamInput';
import {
  GameConfigObjectiveForm,
  GameConfigFormInput
} from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';
import { FormComponent } from '@ui/hooks/form';

import styles from './InputGroup.module.scss';

type Props = Pick<FormComponent<GameConfigObjectiveForm>, 'control' | 'register'> & {
  watch: UseFormWatch<GameConfigObjectiveForm>;
  setFocus: UseFormSetFocus<GameConfigObjectiveForm>;
  gameConfigParams: GLDParamDto[];
};

export function InputGroup({ register, control, watch, setFocus, gameConfigParams }: Props) {
  /*
  nested field change doesn't trigger re-render of parent component,
  so in this case we need to watch configs to be sure we are working with updated data
  */
  const [defaultValue, watchConfigs] = watch(['defaultValue', 'config']);
  const { fields: configs, replace: replaceConfig } = useFieldArray({
    control,
    name: 'config'
  });
  const {
    fields: params,
    append: appendParam,
    remove: removeParam,
    replace: replaceParams
  } = useFieldArray({
    control,
    name: 'params'
  });

  // update(add) all configs on add a new param in a table header
  const updateConfig = useCallback(
    (param: string) => {
      const deepCopy = cloneDeep(watchConfigs);
      const updatedConfigs = deepCopy.map((config) => {
        const variableDto = gameConfigParams.find((dto) => dto.name === param);
        const value = variableDto ? variableDto.defaultValue : defaultValue;

        const newInput = new GameConfigFormInput(param, value);
        config.input.push(newInput);
        return config;
      });

      replaceConfig(updatedConfigs);
    },
    [watchConfigs, replaceConfig, defaultValue, gameConfigParams]
  );

  // update param name in config on update param name
  const updateConfigParam = useCallback(
    (newParam: string, index: number) => {
      const deepCopy = cloneDeep(watchConfigs);
      const updatedConfigs = deepCopy.map((config) => {
        config.input[index].key = newParam;
        return config;
      });

      replaceConfig(updatedConfigs);
    },
    [watchConfigs, replaceConfig]
  );

  // update(remove) all configs on remove a param in a table header
  const cleanUpConfig = useCallback(
    (param: string) => {
      const deepCopy = cloneDeep(configs);
      const updatedConfigs = deepCopy.map((config) => {
        config.input = config.input.filter(({ key }) => key !== param);
        return config;
      });

      replaceConfig(updatedConfigs);
    },
    [configs, replaceConfig]
  );

  // add a new param
  const handleAddParam = useCallback(
    (data) => {
      appendParam(data);
      updateConfig(data.value);
    },
    [appendParam, updateConfig]
  );

  // update param on edit
  const handleUpdateParam = useCallback(
    (newParam: string, index: number) => {
      const updatedParams = cloneDeep(params);
      updatedParams[index].value = newParam;

      replaceParams(updatedParams);
      updateConfigParam(newParam, index);
    },
    [params, replaceParams, updateConfigParam]
  );

  // remove param
  const handleRemoveParam = useCallback(
    ({ currentTarget }) => {
      const { index, value } = currentTarget.dataset;

      removeParam(index);
      cleanUpConfig(value);
    },
    [removeParam, cleanUpConfig]
  );

  return (
    <>
      <fieldset className={styles.fieldset}>
        <AddParamInput handleAddParam={handleAddParam} watch={watch} gameConfigParams={gameConfigParams} />
        <DefaultValueInput register={register} />
      </fieldset>
      <div className={styles.inputGroup}>
        <table className={styles.table}>
          <Thead
            watch={watch}
            params={params}
            handleRemoveParam={handleRemoveParam}
            handleUpdateParam={handleUpdateParam}
          />
          <TBody control={control} configs={configs} setFocus={setFocus} />
        </table>
      </div>
    </>
  );
}
