import React, { useEffect } from 'react';
import { DropdownInput } from 'crazy-ui-next';
import { Control, useController, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { memoize } from 'proxy-memoize';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { BasicInfoParams } from '@pages/createDirectConfig/basicInfo/types/BasicInfoParams';
import { useSelector } from '@ui/hooks/redux';

import styles from '@pages/createExperiment/basicInfo/BasicInfo.module.scss';

const inputName = 'bundleId';
const watchNames = ['gameName', 'platform'];

type Props = {
  control?: Control<BasicInfoParams>;
  watch: UseFormWatch<BasicInfoParams>;
  setValue: UseFormSetValue<BasicInfoParams>;
};

export function BundleIdInput({ control, watch, setValue }: Props) {
  const [gameName, platform] = watch(['gameName', 'platform']);
  const options = useSelector(memoize(createExperimentSelectors.getBundleIdOptions(gameName, platform)));
  const { field, formState } = useController({ control, name: inputName });
  const disabled = !(gameName && platform);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name && watchNames.includes(name) && type === 'change') {
        setValue(inputName, '');
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  useEffect(() => {
    if (options.length === 1 && !field.value) {
      setValue('bundleId', options[0].value);
    }
  }, [setValue, options, field]);

  return (
    <DropdownInput
      name={inputName}
      labelText="Bundle ID"
      placeholder="Select bundle ID"
      value={field.value}
      options={options}
      className={styles.input}
      disabled={disabled}
      handleChange={(event) => field.onChange(event.value)}
      error={formState.errors?.[inputName]?.message}
    />
  );
}
