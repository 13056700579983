import React from 'react';
import cn from 'classnames';

import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ExperimentFormFormatter } from '@app/mappers/experiment/ExperimentFormFormatter';

import { DescriptionCell } from '@pages/directConfigDetails/components/summary/config/runningForm/sections/groups/atoms/DescriptionCell';

import styles from '../GroupsConfig.module.scss';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';

type Props = {
  config: GenericConfigEntry;
  variables: string[];
  isAdProfile: boolean;
};

export function TRow({ variables, config, isAdProfile }: Props) {
  const { entry, active } = config;

  const cells = variables.map((param) => {
    if (!entry[param]) {
      return (
        <td className={styles.emptyValue} key={param}>
          {ExperimentFormFormatter.formatGLDValue(null)}
        </td>
      );
    }

    const { configValue, type } = entry[param];

    if (type === ExperimentObjectiveSessionType.SESSION) {
      const sessions = Object.keys(configValue);

      return (
        <td key={param}>
          {sessions.map((session, sessionIndex) => {
            const { value } = configValue[session];

            return (
              <p key={`${sessionIndex}_${session}`}>
                {session}: {String(value)}
              </p>
            );
          })}
        </td>
      );
    }

    const { value } = configValue;

    return (
      <td className={cn(value === '' && styles.emptyValue)} key={param}>
        {ExperimentFormFormatter.formatGLDValue(value)}
      </td>
    );
  });

  return (
    <tr className={cn(!active && styles.inactiveGroup)}>
      {!isAdProfile && <DescriptionCell config={config} />}
      {cells}
    </tr>
  );
}
