import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { GameConfigForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';

export class GameConfigFormMapper {
  static toGenericConfig(config: GameConfigForm[]): GenericConfigEntry {
    const entry = config[0].input.reduce((acc, { key, value }) => {
      if (value === null) {
        return acc;
      }
      acc[key] = {
        type: ExperimentObjectiveSessionType.PLAIN,
        configValue: { type: ExperimentVariableWrapper.STRING, value }
      };
      return acc;
    }, {});

    return {
      name: 'A',
      description: null,
      active: true,
      entry
    };
  }
}
