import React from 'react';
import { InputLabel, TextInput } from 'crazy-ui-next';

import { InDevFormParams } from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';
import { FormComponent, getInputProps } from '@ui/hooks/form';

import styles from '../InDevForm.module.scss';

type Props = Pick<FormComponent<InDevFormParams>, 'control' | 'register' | 'errors'>;

export function BasicInfoSection({ register, errors }: Props) {
  return (
    <ul className={styles.formSection}>
      <li>
        <InputLabel labelText="Targeting Name:" className={styles.editorInputLabel} />
        <TextInput className={styles.wideInput} {...getInputProps<InDevFormParams>('name', register, errors)} />
      </li>

      <li>
        <InputLabel labelText="Imported Segments:" className={styles.editorInputLabel} />
        <TextInput
          className={styles.wideInput}
          {...getInputProps<InDevFormParams>('importedSegments', register, errors)}
        />
      </li>
    </ul>
  );
}
