import React from 'react';
import { InputLabel, RadioInput } from 'crazy-ui-next';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/ControlGroup.module.scss';

type Props<T extends FieldValues> = Pick<FormComponent<T>, 'control'> & {
  name: string;
  labelText: string;
};

export function ControlledBooleanInput<T extends FieldValues>({ control, name, labelText }: Props<T>) {
  return (
    <Controller
      name={`controlGroup.${name}` as Path<T>}
      control={control}
      render={({ field }) => {
        return (
          <>
            <InputLabel labelText={labelText} className={styles.label} />
            <RadioInput
              name={field.name}
              checked={field.value === '1'}
              labelText="On"
              value="1"
              onChange={({ currentTarget }) => {
                field.onChange(currentTarget.value);
              }}
            />
            <RadioInput
              name={field.name}
              checked={field.value === '0'}
              labelText="Off"
              value="0"
              onChange={({ currentTarget }) => {
                field.onChange(currentTarget.value);
              }}
            />
          </>
        );
      }}
    />
  );
}
