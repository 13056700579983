import React from 'react';

import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';

type Props = { params: string[]; variables: ExperimentVariableDto[] };

export function THead({ params, variables }: Props) {
  return (
    <thead>
      <tr>
        {params.map((key) => {
          const currentVariable = variables.find(({ name }) => name === key);
          const displayName = currentVariable ? currentVariable.displayName : '-';

          return <th key={key}>{displayName}</th>;
        })}
      </tr>
    </thead>
  );
}
