import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash-es';

import { directConfigListSelectors } from '@infrastructure/store/directConfigList/directConfigListSelectors';
import { useForm } from '@ui/hooks/form';
import { DirectConfigFilters } from '@pages/directConfigList/filters/DirectConfigFilters';
import { DirectConfigFiltersForm } from '@pages/directConfigList/filters/types/DirectConfigFiltersForm';
import { setFilters } from '@infrastructure/store/directConfigList/directConfigListActions';

export function DirectConfigFiltersContainer() {
  const dispatch = useDispatch();
  const filters = useSelector(directConfigListSelectors.getFilters);

  const { watch, control } = useForm<DirectConfigFiltersForm>({
    schema: DirectConfigFiltersForm,
    defaultValues: filters
  });

  const debouncedDispatch = debounce((value) => dispatch(setFilters(value)), 300);

  useEffect(() => {
    const subscription = watch((value) => {
      debouncedDispatch(value as DirectConfigFiltersForm);
    });
    return () => {
      subscription.unsubscribe();
      debouncedDispatch.cancel();
    };
  }, [debouncedDispatch, dispatch, watch]);

  return <DirectConfigFilters control={control} />;
}
