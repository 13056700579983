import { IsArray, IsBoolean, IsNumberString } from 'class-validator';
import { merge, cloneDeep } from 'lodash-es';
import { FormSummary } from '@pages/createExperiment/atoms/Summary/Summary';
import { UserPropertiesParams } from './userProperties/UserPropertiesParams';

export type ControlGroupSessionForm = {
  [key: string]: string;
  sessionIndex: string;
};

export type InputGroupSessionForm = {
  [key: string]: { value: string }[] | string;
  sessionIndex: string;
};

export class ObjectiveConfigParams {
  @IsArray()
  params: string[];

  @IsBoolean()
  cloneControlGroup: boolean;

  @IsBoolean()
  builtInGroup: boolean;

  @IsArray()
  controlGroup: ControlGroupSessionForm[];

  @IsArray()
  input: { section: InputGroupSessionForm[] }[];

  @IsBoolean()
  adjustableUsersAllocation: boolean;

  @IsBoolean()
  newUsers: boolean;

  @IsBoolean()
  sticky: boolean;

  @IsNumberString()
  usersAllocationPercent: string;

  static ofInitial() {
    const form = new ObjectiveConfigParams();
    form.params = [];
    form.cloneControlGroup = false;
    form.builtInGroup = false;
    form.controlGroup = [{ sessionIndex: '1' }];
    form.input = [{ section: [{ sessionIndex: '1' }] }];
    form.adjustableUsersAllocation = false;
    form.newUsers = true;
    form.sticky = true;
    form.usersAllocationPercent = '100';
    return form;
  }

  getSummary(): FormSummary {
    return [
      { title: 'Clone Control Group', value: String(this.cloneControlGroup) },
      { title: 'Adjustable Percent', value: String(this.adjustableUsersAllocation) },
      { title: 'New Users', value: String(this.newUsers) },
      { title: 'Sticky', value: String(this.sticky) },
      { title: 'Percentage of engaged users', value: `${this.usersAllocationPercent}%` }
    ];
  }

  public setControlGroup(controlGroups: ControlGroupSessionForm[]) {
    this.controlGroup = controlGroups;
    return this;
  }

  public setInputGroup(inputGroups: InputGroupSessionForm[]) {
    this.input = [{ section: inputGroups }];
    return this;
  }

  public setParamsByDAUParams(importedSegments: string, userProperties: UserPropertiesParams) {
    const isImportedSegments = Boolean(importedSegments);
    const nonEmptyUserProperties = userProperties.inputs.filter(({ value }) => value.trim().length);
    const isUserProperties = Boolean(nonEmptyUserProperties.length);

    if (isImportedSegments || isUserProperties) {
      this.newUsers = false;
      this.sticky = false;
    }

    return this;
  }

  public mergeControlGroup(controlGroup: ControlGroupSessionForm) {
    this.controlGroup = this.controlGroup.map((group) => merge(controlGroup, group));
    return this;
  }

  public mergeInputGroup(inputGroup: InputGroupSessionForm) {
    this.input = this.input.map(({ section }) => {
      return {
        section: section.map((group) => {
          const cloneInput = cloneDeep(inputGroup);

          return merge(cloneInput, group);
        })
      };
    });
    return this;
  }
}
