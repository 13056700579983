import { DirectConfigFormState } from '@infrastructure/store/createDirectConfig/createDirectConfigReducer';
import { INewDirectConfig } from '@domain/models/directConfig/NewDirectConfig';
import { ExperimentFormMapper } from '@app/mappers/experiment/ExperimentFormMapper';
import { RegionMapper } from '@app/mappers/experiment/RegionMapper';
import { RegionDto } from '@domain/models/RegionDto';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { DirectConfigType } from '@domain/enums/directConfig/DirectConfigType';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { AdProfileFormMapper } from '@app/mappers/directConfig/AdProfileFormMapper';
import { GameConfigFormMapper } from '@app/mappers/directConfig/GameConfigFormMapper';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { CreateDirectConfigForm } from '@domain/enums/CreateDirectConfigForm';
import { BasicInfoParams } from '@pages/createDirectConfig/basicInfo/types/BasicInfoParams';
import { TargetConfigParams } from '@pages/createDirectConfig/targetConfig/types/TargetConfigParams';
import { GameConfigObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';
import { AdProfileObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/types/AdProfileObjectiveForm';

export class DirectConfigFormMapper {
  static formToCreatePayload(
    form: DirectConfigFormState,
    defaultRegions: RegionDto[],
    variables: ExperimentVariableDto[]
  ): INewDirectConfig {
    const { basicInfo, targetConfig, gameConfigObjective, adProfileObjective } = form;

    const inDevRegionNames = targetConfig.regionsForInDev.map((r) => r.value);
    const inDevRegions = RegionMapper.getRegionsByNames(defaultRegions, inDevRegionNames);

    const liveRegionNames = targetConfig.regions.map((r) => r.value);
    const liveRegions = RegionMapper.getRegionsByNames(defaultRegions, liveRegionNames);

    let genericConfig: GenericConfigEntry;

    if (basicInfo.type === DirectConfigType.AD_PROFILE) {
      genericConfig = AdProfileFormMapper.toGenericConfig(
        adProfileObjective.params,
        adProfileObjective.controlGroup,
        variables
      );
    } else {
      genericConfig = GameConfigFormMapper.toGenericConfig(gameConfigObjective.config);
    }

    return {
      gameId: basicInfo.gameId,
      name: basicInfo.name,
      type: basicInfo.type,
      appliedOperator: targetConfig.appliedOperator,
      appVersions: targetConfig.versions.map((v) => v.value),
      importedSegments: targetConfig.importedSegments,
      userProperties: ExperimentFormMapper.mapInputsToExperimentUserProperty(targetConfig.userProperties),
      inDevRegionIds: inDevRegions.map((r) => r.id),
      liveRegionIds: liveRegions.map((r) => r.id),
      genericConfigList: [genericConfig]
    };
  }

  static directConfigToForm(directConfig: IDirectConfig): DirectConfigFormState {
    const isAdProfile = directConfig.type === DirectConfigType.AD_PROFILE;

    return {
      [CreateDirectConfigForm.BASIC_INFO]: new BasicInfoParams(directConfig),
      [CreateDirectConfigForm.TARGET_CONFIG]: new TargetConfigParams(directConfig),
      [CreateDirectConfigForm.AD_PROFILE_OBJECTIVE]: new AdProfileObjectiveForm(isAdProfile ? directConfig : undefined),
      [CreateDirectConfigForm.GAME_CONFIG_OBJECTIVE]: new GameConfigObjectiveForm(
        isAdProfile ? undefined : directConfig
      )
    };
  }
}
