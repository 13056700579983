import { call, delay, put, select, takeLatest, takeLeading, SagaReturnType } from 'redux-saga/effects';
import { Service } from 'typedi';

import { DirectConfigService } from '@app/services/DirectConfigService';
import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { BaseSagaWatcher } from '@infrastructure/sagas/BaseSagaWatcher';
import { Notification } from '@infrastructure/api/notifications';
import {
  fetchDirectConfigs,
  fetchNextDirectConfigs,
  setFilters
} from '@infrastructure/store/directConfigList/directConfigListActions';
import { directConfigListSelectors } from '@infrastructure/store/directConfigList/directConfigListSelectors';

@Service()
export class DirectConfigListSaga extends BaseSagaWatcher {
  private fetchDelayMs = 500;

  constructor(private service: DirectConfigService, protected rootWatcher: RootSagaWatcher) {
    super(rootWatcher);
  }

  getEffects() {
    return [
      takeLatest(fetchDirectConfigs.TRIGGER, this.getDirectConfigs.bind(this)),
      takeLeading(fetchNextDirectConfigs, this.handleNextFetch.bind(this)),
      takeLatest(setFilters, this.getDirectConfigs.bind(this))
    ];
  }

  *getDirectConfigs(action: ReturnType<typeof fetchDirectConfigs.trigger>) {
    try {
      const page = action.payload;
      yield put(fetchDirectConfigs.request());
      type Result = SagaReturnType<typeof this.service.getList>;

      const result: Result = yield call([this.service, this.service.getList], page);

      yield put(fetchDirectConfigs.success(result));
    } catch (err) {
      yield put(fetchDirectConfigs.failure());
      Notification.error(err);
    }
  }

  *handleNextFetch() {
    const { isNextAvailable, currentPage } = yield select(directConfigListSelectors.getPagination);

    if (isNextAvailable) {
      yield put(fetchDirectConfigs.trigger(currentPage + 1));
      yield delay(this.fetchDelayMs);
    }
  }
}
