import React from 'react';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { useSelector } from '@ui/hooks/redux';

import styles from '@pages/createExperiment/CreateExperiment.module.scss';

export type FormSummary = { title: string; value: string }[];

type Props = {
  step: Exclude<
    CreateExperimentForm,
    CreateExperimentForm.GLD_OBJECTIVE_CONFIG | CreateExperimentForm.AB_OBJECTIVE_CONFIG
  >;
};

export function Summary({ step }: Props) {
  const form = useSelector(createExperimentSelectors.getForm);

  const { experimentType } = form[CreateExperimentForm.BASIC_INFO];

  const params = form[step];
  const summary = params.getSummary(experimentType);

  return (
    <ul className={styles.summary}>
      {summary.map(({ title, value }) => {
        return (
          <li key={`${title}_${value}`}>
            <p>
              {title}: <span>{value}</span>
            </p>
          </li>
        );
      })}
    </ul>
  );
}
