import React from 'react';

import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { CreateDirectConfigForm } from '@domain/enums/CreateDirectConfigForm';
import { useSelector } from '@ui/hooks/redux';

import styles from '@pages/createExperiment/CreateExperiment.module.scss';

type Props = {
  step: CreateDirectConfigForm;
};

export function Summary({ step }: Props) {
  const form = useSelector(createDirectConfigSelectors.getForm);

  const params = form[step];
  // @ts-ignore fixme
  const summary = params.getSummary();

  return (
    <ul className={styles.summary}>
      {summary.map(({ title, value }) => {
        return (
          <li key={`${title}_${value}`}>
            <p>
              {title}: <span>{value}</span>
            </p>
          </li>
        );
      })}
    </ul>
  );
}
