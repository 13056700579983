import { combineReducers } from '@reduxjs/toolkit';
import { fetchDirectConfigs, clearAll } from '@infrastructure/store/directConfigList/directConfigListActions';

import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { paginationReducer } from '@infrastructure/store/directConfigList/reducers/paginationReducer';
import { listReducer } from '@infrastructure/store/directConfigList/reducers/listReducer';
import { filterCacheReducer } from '@infrastructure/store/directConfigList/reducers/filterReducer';

export const directConfigList = combineReducers({
  status: ReducerFactory.makeStatus(fetchDirectConfigs, clearAll),
  list: listReducer,
  pagination: paginationReducer,
  filters: filterCacheReducer
});
