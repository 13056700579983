import React, { useEffect } from 'react';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { BasicInfoForm } from '@pages/createDirectConfig/basicInfo/BasicInfoForm';
import { Summary } from '@pages/createDirectConfig/Summary/Summary';
import { pushBasicInfoForm } from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { useForm } from '@ui/hooks/form';
import { BasicInfoParams } from '@pages/createDirectConfig/basicInfo/types/BasicInfoParams';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { CreateDirectConfigForm } from '@domain/enums/CreateDirectConfigForm';

type Props = {
  isCompleted?: boolean;
};

export function BasicInfoContainer({ isCompleted }: Props) {
  const dispatch = useDispatch();
  const { basicInfo } = useSelector(createDirectConfigSelectors.getForm);

  const {
    register,
    watch,
    control,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors }
  } = useForm<BasicInfoParams>({
    schema: BasicInfoParams,
    defaultValues: basicInfo
  });

  const [platform, firebaseProjectId, bundleId] = watch(['platform', 'firebaseProjectId', 'bundleId']);
  const game = useSelector(configSelectors.getGameAppByPartialData(firebaseProjectId, platform, bundleId));

  useEffect(() => {
    if (game) {
      setValue('gameId', game.id);
    }
  }, [game, setValue]);

  const handleNext = handleSubmit((form) => {
    dispatch(pushBasicInfoForm(form));
  });

  if (isCompleted) {
    return <Summary step={CreateDirectConfigForm.BASIC_INFO} />;
  }

  return (
    <BasicInfoForm
      errors={errors}
      handleSubmit={handleNext}
      register={register}
      watch={watch}
      control={control}
      resetField={resetField}
      setValue={setValue}
    />
  );
}
