import { ILiveConfig } from '@domain/models/experiment/LiveConfig';
import { Row } from '@pages/createExperiment/notifications/liveConfigurations/list/Row';

type Props = {
  configs: ILiveConfig[];
};

export function List({ configs }: Props) {
  return (
    <ul>
      {configs.map((config) => (
        <Row key={config.experimentId} config={config} />
      ))}
    </ul>
  );
}
