import React from 'react';

import { AdProfileFormContainer } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/AdProfileFormContainer';
import { GameConfigFormContainer } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/GameConfigFormContainer';
import { useSelector } from '@ui/hooks/redux';
import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { DirectConfigType } from '@domain/enums/directConfig/DirectConfigType';

type Props = {
  isCompleted?: boolean;
};

export function ObjectiveConfigContainer({ isCompleted }: Props) {
  const { basicInfo } = useSelector(createDirectConfigSelectors.getForm);

  switch (basicInfo.type) {
    case DirectConfigType.AD_PROFILE:
      return <AdProfileFormContainer isCompleted={isCompleted} />;
    case DirectConfigType.GAME_CONFIG:
      return <GameConfigFormContainer isCompleted={isCompleted} />;
    default:
      return null;
  }
}
