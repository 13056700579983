import React from 'react';

import { FormComponent } from '@ui/hooks/form';
import { DirectConfigFiltersForm } from '@pages/directConfigList/filters/types/DirectConfigFiltersForm';
import { SearchInput } from '@pages/directConfigList/filters/inputs/SearchInput';
import { DateRangeInput } from '@pages/directConfigList/filters/inputs/DateRangeInput';
import { BusinessUnitInput } from '@pages/directConfigList/filters/inputs/BusinessUnitInput';
import { PlatformInput } from '@pages/directConfigList/filters/inputs/PlatformInput';
import { StatusInput } from '@pages/directConfigList/filters/inputs/StatusInput';

type Props = Pick<FormComponent<DirectConfigFiltersForm>, 'control'>;

export function DirectConfigFilters({ control }: Props) {
  return (
    <section className="my-4">
      <form className="row">
        <div className="col-4">
          <SearchInput control={control} />
        </div>
        <div className="col-3">
          <DateRangeInput control={control} />
        </div>
        <div className="col-3">
          <BusinessUnitInput control={control} />
        </div>
        <div className="col-3">
          <PlatformInput control={control} />
        </div>
        <div className="col-3">
          <StatusInput control={control} />
        </div>
      </form>
    </section>
  );
}
