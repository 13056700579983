import React from 'react';

import { GoalConfigParams } from '@domain/models/createExperiment/GoalConfigParams';
import { TargetARPUInput } from '@pages/createExperiment/goalConfig/inputs/TargetARPUInput';
import { ReportingARPUInput } from '@pages/createExperiment/goalConfig/inputs/ReportingARPUInput';
import { CheckPeriodInput } from '@pages/createExperiment/goalConfig/inputs/CheckPeriodInput';
import { AutomaticModeInput } from '@pages/createExperiment/goalConfig/inputs/AutomaticModeInput';
import { NextButton } from '@components/layout/NextButton/NextButton';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/goalConfig/GoalConfig.module.scss';

export function GoalConfig({ register, control, handleSubmit, errors }: FormComponent<GoalConfigParams>) {
  return (
    <form onSubmit={handleSubmit} className={styles.goalConfig}>
      <TargetARPUInput errors={errors} control={control} />
      <ReportingARPUInput register={register} control={control} errors={errors} />
      <CheckPeriodInput register={register} control={control} />
      <AutomaticModeInput register={register} />

      <NextButton />
    </form>
  );
}
