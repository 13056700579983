import React from 'react';
import { useSelector } from 'react-redux';
import { memoize } from 'proxy-memoize';

import { configSelectors } from '@infrastructure/store/config/configSelectors';

type Props = {
  variables: string[];
  isAdProfile: boolean;
};

export function THead({ variables, isAdProfile }: Props) {
  const variableTitle = useSelector(memoize(configSelectors.getVariableTitleMap));

  return (
    <thead>
      <tr>
        {!isAdProfile && <th data-wider={true}>Description</th>}

        {variables.map((key) => (
          <th key={key}>{variableTitle[key] || key}</th>
        ))}
      </tr>
    </thead>
  );
}
