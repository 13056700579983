import { IsString, IsArray, ValidateNested } from 'class-validator';

import { FormSummary } from '@pages/createExperiment/atoms/Summary/Summary';

export class UserConfig {
  @IsString()
  value: string;

  @IsString()
  key: string;
}

export class AdvancedConfigParams {
  @IsArray()
  @ValidateNested({ each: true })
  userConfig: UserConfig[];

  @IsString()
  queryTemplate: string;

  static ofInitial() {
    const form = new AdvancedConfigParams();
    const userConfigInitial = new UserConfig();

    form.userConfig = [userConfigInitial];
    form.queryTemplate = '';

    return form;
  }

  getSummary(): FormSummary {
    return [
      { title: 'User Config', value: this.userConfig.map(({ value, key }) => `${key}: ${value}`).join(', ') },
      { title: 'Query template', value: this.queryTemplate }
    ];
  }
}
