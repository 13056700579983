import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { DateRangeEvent, DateRangePicker } from '@components/lib/DateRangePicker/DateRangePicker';
import { DirectConfigFiltersForm } from '@pages/directConfigList/filters/types/DirectConfigFiltersForm';

type Props = Pick<FormComponent<DirectConfigFiltersForm>, 'control'>;

export function DateRangeInput({ control }: Props) {
  const { field: dateFrom } = useController({ control, name: 'dateFrom' });
  const { field: dateTo } = useController({ control, name: 'dateTo' });

  const handleChange = useCallback(
    (e: DateRangeEvent) => {
      const { startDate, endDate } = e;

      dateFrom.onChange(startDate);
      dateTo.onChange(endDate);
    },
    [dateFrom, dateTo]
  );

  return (
    <DateRangePicker
      name="directConfigDateRange"
      startDate={dateFrom.value}
      endDate={dateTo.value}
      handleChange={handleChange}
      labelText="Start Date"
    />
  );
}
