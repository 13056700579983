import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';

import { UniqueId } from '@domain/types';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { GameStatsType } from '@domain/enums/GameStatsType';
import { RegionDto } from '@domain/models/RegionDto';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { InDevFormParams } from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';
import { RunningFormParams } from '@pages/directConfigDetails/components/summary/config/runningForm/types/RunningFormParams';
import { DirectConfigState } from '@domain/enums/directConfig/DirectConfigState';
import { ControlGroupByCountry } from '@domain/models/ControlGroup';

const namespace = 'directConfigDetails';

export const initDirectConfigDetails = ActionFactory.createRequestRoutine<UniqueId, IDirectConfig>(
  'DIRECT_CONFIG_DETAILS_INIT',
  namespace
);
export const fetchDirectConfig = ActionFactory.createRequestRoutine<UniqueId, IDirectConfig>(
  'DIRECT_CONFIG_FETCH',
  namespace
);

export const fetchDefaultRegions = ActionFactory.createRequestRoutine<void, RegionDto[]>(
  'DEFAULT_REGIONS_FETCH',
  namespace
);
export const fetchGameStats = ActionFactory.createRequestRoutine<void, GameStatsDto[]>('GAME_STATS_FETCH', namespace);
export const fetchControlGroups = ActionFactory.createRequestRoutine<void, ControlGroupByCountry>(
  'CONTROL_GROUPS_FETCH',
  namespace
);

export const pushInDevUpdate = ActionFactory.createRequestRoutine<InDevFormParams, IDirectConfig>(
  'DIRECT_CONFIG_IN_DEV_UPDATE',
  namespace
);
export const pushRunningUpdate = ActionFactory.createRequestRoutine<RunningFormParams, IDirectConfig>(
  'DIRECT_CONFIG_RUNNING_UPDATE',
  namespace
);
export const pushStateUpdate = ActionFactory.createRequestRoutine<DirectConfigState, IDirectConfig>(
  'DIRECT_CONFIG_START_PUSH',
  namespace
);

export const setGameStatsFilter = ActionFactory.createAction<GameStatsType>('GAME_STATS_FILTER_SET', namespace);

export const fetchGameInstallsStats = ActionFactory.createRequestRoutine<
  RegionDto,
  { region: string; stats: GameInstallsStatsDto }
>('GAME_INSTALLS_STATS_FETCH', namespace);

export const clearAll = ActionFactory.createAction('CLEAR_ALL', namespace);
