import React, { useCallback, useEffect, useState } from 'react';
import { Control, useFieldArray, UseFormRegister, useWatch } from 'react-hook-form';
import { TextInput } from 'crazy-ui-next';
import cn from 'classnames';

import { VersionOperator } from '@domain/enums/VersionOperator';
import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';
import { TargetConfigParams } from '@pages/createDirectConfig/targetConfig/types/TargetConfigParams';
import { getInputProps } from '@ui/hooks/form';

import styles from '@pages/createDirectConfig/targetConfig/TargetConfig.module.scss';

type Props = {
  control: Control<TargetConfigParams>;
  register: UseFormRegister<TargetConfigParams>;
};

export function VersionsInput({ control, register }: Props) {
  const versionOperator = useWatch<TargetConfigParams>({ control, name: 'appliedOperator' });
  const [maxVersions, setMaxVersions] = useState(5);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'versions'
  });

  const onAdd = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  useEffect(() => {
    if (versionOperator === VersionOperator.EQUAL && maxVersions !== 5) {
      setMaxVersions(5);
    }

    if (versionOperator !== VersionOperator.EQUAL && maxVersions === 5) {
      const indexesToRemove = fields.slice(1).map((_, index) => index + 1);

      remove(indexesToRemove);
      setMaxVersions(1);
    }
  }, [versionOperator, maxVersions, fields, remove, setMaxVersions]);

  const inputs = fields.map((field, index) => {
    return (
      <TextInput
        className={styles.multiInputEl}
        key={field.id}
        {...getInputProps<TargetConfigParams>(`versions.${index}.value`, register, {})}
      />
    );
  });

  return (
    <MultiTextInputContainer
      inputs={inputs}
      onAddItem={onAdd}
      onRemoveItem={onRemove}
      maxItems={maxVersions}
      className={cn(styles.inputWrapper, styles.multiInputWrapper)}
    />
  );
}
