import React from 'react';
import { useController, Control, FieldValues, Path } from 'react-hook-form';
import { TextInput } from 'crazy-ui-next';

type Props<T extends FieldValues> = {
  inputIndex: number;
  control: Control<T>;
  disabled: boolean;
  watchName: Path<T>;
};

export function UserPropertyTextInput<T extends FieldValues>({ inputIndex, control, disabled, watchName }: Props<T>) {
  const { field } = useController({
    // @ts-ignore-next-line
    name: `${watchName}.${inputIndex}.value`,
    control
  });

  // @ts-ignore-next-line
  return <TextInput name={field.name} value={field.value} onChange={field.onChange} disabled={disabled} />;
}
