import React, { useCallback } from 'react';
import { DropdownEvent, DropdownInput, DropdownOption } from 'crazy-ui-next';
import { useController } from 'react-hook-form';

import { BusinessUnit, BusinessUnitLabels } from '@domain/enums/directConfig/BusinessUnit';
import { DefaultFormValue, DefaultFormValueLabels, FormComponent } from '@ui/hooks/form';
import { DirectConfigFiltersForm } from '@pages/directConfigList/filters/types/DirectConfigFiltersForm';
import { ReactComponent as PathSvg } from '@assets/svg/path.svg';

const options: DropdownOption<BusinessUnit | DefaultFormValue>[] = [
  { value: DefaultFormValue.ALL, label: DefaultFormValueLabels[DefaultFormValue.ALL] },
  { value: BusinessUnit.CASUAL, label: BusinessUnitLabels[BusinessUnit.CASUAL] },
  { value: BusinessUnit.HYPER_CASUAL, label: BusinessUnitLabels[BusinessUnit.HYPER_CASUAL] }
];

type Props = Pick<FormComponent<DirectConfigFiltersForm>, 'control'>;

export function BusinessUnitInput({ control }: Props) {
  const { field } = useController({ control, name: 'businessUnit' });

  const handleChange = useCallback(
    (e: DropdownEvent<BusinessUnit | DefaultFormValue>) => {
      field.onChange(e.value);
    },
    [field]
  );

  return (
    <DropdownInput
      name={field.name}
      inputIcon={<PathSvg />}
      value={field.value}
      options={options}
      handleChange={handleChange}
      labelText="Business Line"
    />
  );
}
