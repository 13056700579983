import React from 'react';
import { InputLabel } from 'crazy-ui-next';

import { VersionInputContainer } from '@pages/winnerConfigModal/form/inputs/VersionInputContainer';
import { VersionOperatorInput } from '@pages/winnerConfigModal/form/inputs/VersionOperatorInput';
import { FormComponent } from '@ui/hooks/form';
import { UpdateWinnerConfigParams } from '@domain/models/winnerConfig/WinnerConfigFormParams';

import styles from '@pages/winnerConfigModal/WinnerConfigModal.module.scss';

type Props = FormComponent<UpdateWinnerConfigParams>;

export function AudienceInputs({ control, register, errors, handleSubmit }: Props) {
  return (
    <div className={styles.audience}>
      <div className={styles.inputWrapper}>
        <InputLabel labelText="Version" />
        <VersionOperatorInput control={control} />
        <VersionInputContainer control={control} register={register} errors={errors} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
}
