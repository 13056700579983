import React, { useEffect } from 'react';
import { Control, useController, UseFormResetField } from 'react-hook-form';

import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { TargetConfigParams } from '@pages/createDirectConfig/targetConfig/types/TargetConfigParams';
import { SelectInput } from '@components/lib/Select/SelectInput';
import { useSelector } from '@ui/hooks/redux';
import { RegionMapper } from '@app/mappers/experiment/RegionMapper';
import { ArrayUtils } from '@app/utils/ArrayUtils';

import styles from '@pages/createDirectConfig/targetConfig/TargetConfig.module.scss';

const inputName = 'regions';

type Props = {
  control: Control<TargetConfigParams>;
  resetField: UseFormResetField<TargetConfigParams>;
};

export function LiveRegionsInput({ control, resetField }: Props) {
  const { field, formState } = useController({ control, name: inputName });

  const { defaultRegions, recommendedRegions } = useSelector(createDirectConfigSelectors.getRegions);

  const options = RegionMapper.createOptions([...defaultRegions, ...recommendedRegions]);

  useEffect(() => {
    if (defaultRegions.length) {
      const regions = RegionMapper.getRegionsByNames(
        defaultRegions,
        field.value.map((option) => option.value)
      );
      const defaultValue = RegionMapper.createOptions(regions);
      const shouldReset = !ArrayUtils.isEqualObjects(field.value, defaultValue);

      shouldReset && resetField(inputName, { defaultValue });
    }
  }, [defaultRegions, field, resetField]);

  return (
    <SelectInput
      name="regions"
      labelText="Live Regions"
      placeholder="Select regions"
      value={field.value}
      options={options}
      handleChange={field.onChange}
      error={formState.errors?.regions?.message}
      className={styles.input}
      isMulti
      isClearable
    />
  );
}
