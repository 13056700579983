import { call, put, takeLatest, all } from 'redux-saga/effects';
import { Service } from 'typedi';

import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { BaseSagaWatcher } from '@infrastructure/sagas/BaseSagaWatcher';
import {
  pushBasicInfoForm,
  pushTargetConfigForm,
  initTargetConfig,
  generateTargetConfig,
  fetchRegions,
  fetchGameStats,
  pushDirectConfigCreate,
  pushNextStep,
  initAdProfileObjective,
  fetchObjectiveConfig,
  fetchGameConfigParams,
  initGameConfigObjective,
  pushAdProfileObjectiveForm,
  pushGameConfigObjectiveForm,
  fetchLiveConfigs,
  validateOverlapping,
  pushClone
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { fetchUserProperties } from '@infrastructure/store/config/configActions';
import { Notification } from '@infrastructure/api/notifications';
import { UserPropertiesDto } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { CreateDirectConfigService } from '@app/services/CreateDirectConfigService';

@Service()
export class CreateDirectConfigSaga extends BaseSagaWatcher {
  constructor(private service: CreateDirectConfigService, protected rootWatcher: RootSagaWatcher) {
    super(rootWatcher);
  }

  getEffects() {
    return [
      takeLatest(pushBasicInfoForm, this.handleNextStep.bind(this)),
      takeLatest(pushTargetConfigForm, this.handleNextStep.bind(this)),
      takeLatest(pushAdProfileObjectiveForm, this.handleNextStep.bind(this)),
      takeLatest(pushGameConfigObjectiveForm, this.handleNextStep.bind(this)),

      takeLatest(initTargetConfig.trigger, this.handleInitTargetConfig.bind(this)),
      takeLatest(initTargetConfig.success, this.generateTargetConfig.bind(this)),
      takeLatest(initTargetConfig.trigger, this.fetchLiveConfigs.bind(this)),

      takeLatest(initAdProfileObjective, this.fetchObjectiveConfig.bind(this)),
      takeLatest(initGameConfigObjective, this.fetchGameConfigParams.bind(this)),
      takeLatest(initAdProfileObjective, this.validateOverlappingExperiments.bind(this)),
      takeLatest(initGameConfigObjective, this.validateOverlappingExperiments.bind(this)),

      takeLatest(pushDirectConfigCreate.trigger, this.pushCreate.bind(this)),
      takeLatest(pushDirectConfigCreate.success, this.afterCreate.bind(this)),

      takeLatest(pushClone.trigger, this.pushClone.bind(this))
    ];
  }

  public *handleNextStep() {
    yield put(pushNextStep());
  }

  public *fetchRegions() {
    try {
      yield put(fetchRegions.request());
      const result = yield call([this.service, this.service.fetchRegions]);

      yield put(fetchRegions.success(result));
    } catch (err) {
      yield put(fetchRegions.failure());
      Notification.error(err);
    }
  }

  public *fetchGameStats() {
    try {
      yield put(fetchGameStats.request());
      const result = yield call([this.service, this.service.fetchGameStats]);

      yield put(fetchGameStats.success(result));
    } catch (err) {
      yield put(fetchGameStats.failure());
      Notification.warning('Failed to fetch game stats');
    }
  }

  public *fetchUserProperties() {
    try {
      yield put(fetchUserProperties.request());

      const result: UserPropertiesDto = yield call([this.service, this.service.fetchUserProperties]);

      yield put(fetchUserProperties.success(result));
    } catch (err) {
      yield put(fetchUserProperties.failure());
      Notification.error(err);
    }
  }

  public *generateTargetConfig() {
    try {
      yield put(generateTargetConfig.request());

      const result = yield call([this.service, this.service.generateTargetConfig]);

      yield put(generateTargetConfig.success(result));
    } catch (err) {
      yield put(generateTargetConfig.failure());
      Notification.error(err);
    }
  }

  public *fetchObjectiveConfig() {
    try {
      yield put(fetchObjectiveConfig.request());
      const result = yield call([this.service, this.service.fetchControlGroups]);

      yield put(fetchObjectiveConfig.success(result));
    } catch (err) {
      yield put(fetchObjectiveConfig.failure());
      Notification.warning('Failed to fetch control group from appsdb');
    }
  }

  public *fetchGameConfigParams() {
    try {
      yield put(fetchGameConfigParams.request());
      const result = yield call([this.service, this.service.getGameConfigParams]);

      yield put(fetchGameConfigParams.success(result));
    } catch (err) {
      yield put(fetchGameConfigParams.failure());
      Notification.warning('Failed to fetch imported GLD params');
    }
  }

  public *pushCreate() {
    try {
      yield put(pushDirectConfigCreate.request());

      yield call([this.service, this.service.create]);

      yield put(pushDirectConfigCreate.success());
      Notification.success('The direct config has been created');
    } catch (err) {
      yield put(pushDirectConfigCreate.failure());
      Notification.error(err);
    }
  }

  *afterCreate() {
    yield call([this.service, this.service.navigateToMainPage]);
  }

  /*
    this saga wraps an old logic
    due to the fact we need a single initTargetConfig status we should handle all the fetches in one saga
    possible refactor: handle all in a service, but it will require custom error handling and proper testing
 */
  public *handleInitTargetConfig() {
    try {
      yield put(initTargetConfig.request());

      yield all([
        call([this, this.fetchRegions]),
        call([this, this.fetchGameStats]),
        call([this, this.fetchUserProperties])
      ]);

      yield put(initTargetConfig.success());
    } catch (err) {
      yield put(initTargetConfig.failure());
      Notification.error(err);
    }
  }

  public *fetchLiveConfigs() {
    try {
      yield put(fetchLiveConfigs.request());

      const payload = yield call([this.service, this.service.fetchLiveConfigs]);

      yield put(fetchLiveConfigs.success(payload));
    } catch (err) {
      yield put(fetchLiveConfigs.failure());
      Notification.error('Failed to fetch overlapped experiment');
    }
  }

  public *validateOverlappingExperiments() {
    try {
      yield put(validateOverlapping.request());

      const payload = yield call([this.service, this.service.validateOverlappingExperiments]);

      yield put(validateOverlapping.success(payload));
    } catch (err) {
      yield put(validateOverlapping.failure());
      Notification.error('Failed to fetch overlapped experiment');
    }
  }

  public *pushClone() {
    try {
      yield put(pushClone.request());

      const payload = yield call([this.service, this.service.clone]);

      yield put(pushClone.success(payload));
    } catch (err) {
      yield put(pushClone.failure());
      Notification.error(err);
    }
  }
}
