import React from 'react';
import { Switch } from 'react-router-dom';

import { Routing } from '@infrastructure/routing';

import { CreateExperimentContainer } from '@pages/createExperiment/CreateExperimentContainer';
import { UserManagementContainer } from '@pages/userManagement/UserManagementContainer';
import { SignInContainer } from '@pages/auth/SignInFormContainer';
import { ExperimentListPageContainer } from '@pages/experimentList/ExperimentListPageContainer';
import { DirectConfigListPageContainer } from '@pages/directConfigList/DirectConfigListPageContainer';
import { EditUserContainer } from '@pages/userManagement/userForm/EditUserContainer';
import { CreateUserContainer } from '@pages/userManagement/userForm/CreateUserContainer';
import { AppPropertiesPageContainer } from '@pages/appProperties/AppPropertiesPageContainer';
import { ExpVariablesContainer } from '@pages/experimentVariables/ExpVariablesContainer';
import { CreateExpVariableContainer } from '@pages/experimentVariables/form/CreateExpVariableContainer';
import { EditExpVariableContainer } from '@pages/experimentVariables/form/EditExpVariableContainer';
import { AppliedConfigPageContainer } from '@pages/appliedConfig/AppliedConfigPageContainer';
import { NotFound } from '@pages/common/NotFound';
import { PermissionDenied } from '@pages/common/PermissionDenied';
import { ExperimentDetailsContainer } from '@pages/experimentDetails/ExperimentDetailsContainer';
import { CreateDirectConfigContainer } from '@pages/createDirectConfig/CreateDirectConfigContainer';
import { DirectConfigDetailsContainer } from '@pages/directConfigDetails/DirectConfigDetailsContainer';

import { WrappedRoute } from '@components/routes/WrappedRoute';
import { AuthRoute } from '@components/routes/AuthRoute';
import { RoleRoute } from '@components/routes/RoleRoute';

export function RouterSwitch() {
  return (
    <Switch>
      <WrappedRoute exact path={Routing.getLogin()} component={SignInContainer} />
      <AuthRoute
        exact
        path={Routing.getCreateExperiment()}
        component={CreateExperimentContainer}
        pageName="New Experiment"
      />
      <AuthRoute
        exact
        path={Routing.getCreateDirectConfig()}
        component={CreateDirectConfigContainer}
        pageName="New Segment Targeting"
      />
      <RoleRoute exact path={Routing.getUsers()} forAdmin={UserManagementContainer} />
      <RoleRoute exact path={Routing.getCreateUser()} forAdmin={CreateUserContainer} pageName="Create User" />
      <RoleRoute exact path={Routing.getEditUser()} forAdmin={EditUserContainer} pageName="Edit User" />

      <AuthRoute exact path={Routing.getExperiment()} component={ExperimentDetailsContainer} />
      <AuthRoute exact path={Routing.getDirectConfig()} component={DirectConfigDetailsContainer} />
      <AuthRoute exact path={Routing.getDirectConfig()} component={NotFound} />

      <AuthRoute exact path={Routing.getExperimentList()} component={ExperimentListPageContainer} />
      <AuthRoute exact path={Routing.getDirectConfigList()} component={DirectConfigListPageContainer} />
      <AuthRoute exact path={Routing.getAppliedConfigs()} component={AppliedConfigPageContainer} />
      <RoleRoute exact path={Routing.getExpVariables()} forAdmin={ExpVariablesContainer} />
      <RoleRoute exact path={Routing.getCreateExpVariable()} forAdmin={CreateExpVariableContainer} />
      <RoleRoute exact path={Routing.getEditExpVariable()} forAdmin={EditExpVariableContainer} />
      <RoleRoute
        exact
        path={Routing.getSystemConfig()}
        forAdmin={AppPropertiesPageContainer}
        pageName="System Configuration"
      />
      <AuthRoute exact path={Routing.getRoot()} component={ExperimentListPageContainer} />
      <WrappedRoute exact path={Routing.getPermissionDenied()} component={PermissionDenied} />
      <WrappedRoute component={NotFound} />
    </Switch>
  );
}
