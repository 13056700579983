import React from 'react';

import { DirectConfigState, DirectConfigStateLabels } from '@domain/enums/directConfig/DirectConfigState';

import styles from './DirectConfigStatus.module.scss';

type Props = {
  status: DirectConfigState;
};

export function DirectConfigStatus({ status }: Props) {
  return (
    <span data-status={status} className={styles.status}>
      {DirectConfigStateLabels[status]}
    </span>
  );
}
