import { isEqual } from 'lodash-es';

import { RegionMapper } from '@app/mappers/experiment/RegionMapper';
import { RegionDto } from '@domain/models/RegionDto';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import {
  InDevConfigFormInput,
  InDevFormParams
} from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';
import { IUpdateDirectConfig } from '@domain/models/directConfig/UpdateDirectConfig';
import { ExperimentDetailsMapper } from '@app/mappers/experiment/ExperimentDetailsMapper';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';
import { RunningFormParams } from '@pages/directConfigDetails/components/summary/config/runningForm/types/RunningFormParams';
import { DirectConfigType } from '@domain/enums/directConfig/DirectConfigType';
import { AdProfileFormMapper } from '@app/mappers/directConfig/AdProfileFormMapper';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';

export class DirectConfigDetailsMapper {
  static inDevConfigInputToGenericConfig(input: InDevConfigFormInput[]): GenericConfigEntry {
    const entry = input.reduce((acc, { key, value }) => {
      acc[key] = {
        configValue: { value, type: ExperimentVariableWrapper.STRING },
        type: ExperimentObjectiveSessionType.PLAIN
      };
      return acc;
    }, {});

    return { name: 'A', description: null, active: true, entry };
  }

  static inDevFormToUpdatePayload(
    form: InDevFormParams,
    directConfig: IDirectConfig,
    defaultRegions: RegionDto[],
    variables: ExperimentVariableDto[]
  ): Partial<IUpdateDirectConfig> {
    const payload: Partial<IUpdateDirectConfig> = {};
    const isAdProfile = directConfig.type === DirectConfigType.AD_PROFILE;

    if (form.name !== directConfig.name) {
      payload.newName = form.name;
    }

    const newAppVersions = form.appVersions.map(({ value }) => value);

    if (!isEqual(newAppVersions, directConfig.appVersions)) {
      payload.newAppVersions = newAppVersions;
    }

    if (form.appliedOperator !== directConfig.appliedOperator) {
      payload.newAppliedOperator = form.appliedOperator;
    }

    if (form.importedSegments !== directConfig.importedSegments) {
      payload.newImportedSegments = form.importedSegments;
    }

    const formLiveRegionNames = form.liveRegions.map(({ value }) => value);
    const newLiveRegions = RegionMapper.getRegionsByNames(defaultRegions, formLiveRegionNames);

    if (!isEqual(newLiveRegions, directConfig.liveRegions)) {
      payload.newLiveRegionIds = newLiveRegions.map(({ id }) => id);
    }

    const formInDevRegionNames = form.inDevRegions.map(({ value }) => value);
    const newInDevRegions = RegionMapper.getRegionsByNames(defaultRegions, formInDevRegionNames);

    if (!isEqual(newInDevRegions, directConfig.inDevRegions)) {
      payload.newInDevRegionIds = newInDevRegions.map(({ id }) => id);
    }

    const newUserProperties = ExperimentDetailsMapper.mapInputsToExperimentUserProperty(
      form.userProperties,
      directConfig.userProperties
    );

    if (!isEqual(newUserProperties, directConfig.userProperties)) {
      payload.newUserProperties = newUserProperties;
    }

    let newGenericConfigList: GenericConfigEntry;
    if (isAdProfile) {
      newGenericConfigList = AdProfileFormMapper.toGenericConfig(form.params, form.controlGroup, variables);
    } else {
      newGenericConfigList = this.inDevConfigInputToGenericConfig(form.config);
    }

    if (!isEqual(newGenericConfigList, directConfig.genericConfigList[0])) {
      payload.newGenericConfigList = [newGenericConfigList];
    }

    return payload;
  }

  static runningFormToUpdatePayload(
    form: RunningFormParams,
    directConfig: IDirectConfig
  ): Partial<IUpdateDirectConfig> {
    const payload: Partial<IUpdateDirectConfig> = {};

    const newAppVersions = form.appVersions.map(({ value }) => value);

    if (!isEqual(newAppVersions, directConfig.appVersions)) {
      payload.newAppVersions = newAppVersions;
    }

    if (form.appliedOperator !== directConfig.appliedOperator) {
      payload.newAppliedOperator = form.appliedOperator;
    }

    if (form.importedSegments !== directConfig.importedSegments) {
      payload.newImportedSegments = form.importedSegments;
    }

    const newUserProperties = ExperimentDetailsMapper.mapInputsToExperimentUserProperty(
      form.userProperties,
      directConfig.userProperties
    );

    if (!isEqual(newUserProperties, directConfig.userProperties)) {
      payload.newUserProperties = newUserProperties;
    }

    return payload;
  }
}
