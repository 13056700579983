import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { Service } from 'typedi';
import { PageReqParams, Paginate } from '@domain/models/Paginate';
import { UniqueId } from '@domain/types';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { DirectConfigsRequestParams } from '@domain/models/directConfig/DirectConfigsRequestParams';
import { OffsetPagination } from '@domain/models/local/OffsetPagination';
import { INewDirectConfig } from '@domain/models/directConfig/NewDirectConfig';
import { IUpdateDirectConfig } from '@domain/models/directConfig/UpdateDirectConfig';

@Service()
export class DirectConfigApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async getList(filters: DirectConfigsRequestParams, pagination?: OffsetPagination): Promise<Paginate<IDirectConfig>> {
    let params: DirectConfigsRequestParams | PageReqParams = filters || {};

    if (pagination) {
      params = { ...params, ...pagination.getParams() };
    }

    return this.http.get('/intops/admin/direct-config', params);
  }

  async get(id: UniqueId): Promise<IDirectConfig> {
    return this.http.get(`/intops/admin/direct-config/${id}`);
  }

  async create(body: INewDirectConfig): Promise<IDirectConfig> {
    return this.http.post('/intops/admin/direct-config', body);
  }

  async update(id: UniqueId, body: Partial<IUpdateDirectConfig>): Promise<IDirectConfig> {
    return this.http.put(`/intops/admin/direct-config/${id}`, body);
  }
}
