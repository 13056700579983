import React from 'react';

import { useToggle } from '@ui/hooks/useToggle';
import { Image } from '@components/layout/Image';
import { PlatformIcon } from '@components/layout/platformIcon/PlatformIcon';
import { CountryLabel } from '@components/layout/countryLabel/CountryLabel';
import { InnerTable } from '@pages/appliedConfig/table/InnerTable';
import { AppliedConfigDto } from '@domain/models/appliedConfig/AppliedConfigDto';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';
import { ReactComponent as ArrowSvg } from '@assets/svg/arrow-up-circle.svg';

import styles from './AppliedConfigList.module.scss';
import { ResourceMapper } from '@app/mappers/ResourceMapper';
import { AppliedConfigStatsDto } from '@domain/models/appliedConfig/AppliedConfigStatsDto';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ExpTypeTitleMap } from '@ui/const/ExpTypeTitleMap';

type Props = {
  game: GameApplicationDto;
  stats: AppliedConfigStatsDto;
  configs: AppliedConfigDto[];
};

const getUniqueRegions = (configs: AppliedConfigDto[]): string[] => [
  ...new Set(configs.map((x) => x.region.countryCodes[0] || x.region.name))
];

export function Row({ game, stats, configs }: Props) {
  const { isActive, handleToggle } = useToggle();

  const iconUrl = ResourceMapper.mapS3UrlToCDN(game.iconUrl);
  const abCount = stats.countMap[ExperimentType.AB_TESTING] || '0';
  const gldCount = stats.countMap[ExperimentType.GLD_TEST] || '0';
  const regionList = getUniqueRegions(configs);

  return (
    <>
      <li data-id={game.id} data-open={isActive} data-kind="row" className={styles.row} onClick={handleToggle}>
        <div className={styles.icon}>
          <Image src={iconUrl} alt={`${game.gameName} icon`} />
        </div>

        <p>{game.gameName}</p>

        <div className={styles.stats} data-stats={ExperimentType.AB_TESTING}>
          <span>{abCount}</span>
          <label>{ExpTypeTitleMap[ExperimentType.AB_TESTING]}</label>
        </div>

        <div className={styles.stats} data-stats={ExperimentType.GLD_TEST}>
          <span>{gldCount}</span>
          <label>{ExpTypeTitleMap[ExperimentType.GLD_TEST]}</label>
        </div>

        <div className={styles.platform} data-platform={stats.platform}>
          <label>Platform:</label>
          <span>
            <PlatformIcon platform={stats.platform} />
          </span>
        </div>

        <div className={styles.region}>
          <label>Region:</label>
          <CountryLabel countryCode={regionList} visibleCount={6} />
        </div>

        <div className={styles.control}>
          <button type="button" data-open={isActive}>
            <ArrowSvg />
          </button>
        </div>
      </li>

      <li data-id={game.id} data-open={isActive} data-kind="inner-table" className={styles.innerTable}>
        <InnerTable gameId={game.id} configs={configs} />
      </li>
    </>
  );
}
