import React from 'react';

import { useSelector } from 'react-redux';
import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';
import { DirectConfigType } from '@domain/enums/directConfig/DirectConfigType';
import styles from '@pages/directConfigDetails/components/summary/config/runningForm/sections/groups/GroupsConfig.module.scss';
import { THead } from '@pages/directConfigDetails/components/summary/config/runningForm/sections/groups/atoms/THead';
import { TBody } from '@pages/directConfigDetails/components/summary/config/runningForm/sections/groups/atoms/TBody';

export function GenericConfigContainer() {
  const { type, genericConfigList } = useSelector(directConfigDetailsSelectors.getDirectConfig);
  const variables = Object.keys(genericConfigList[0].entry);
  const isAdProfile = type === DirectConfigType.AD_PROFILE;

  const titleText = isAdProfile ? 'Ad Profiles Groups' : 'Game Config Groups';

  return (
    <div className={styles.tableWrapper}>
      <p className={styles.tableTitle}>{titleText}: </p>
      <table className={styles.table}>
        <THead variables={variables} isAdProfile={isAdProfile} />
        <TBody variables={variables} isAdProfile={isAdProfile} config={genericConfigList[0]} />
      </table>
    </div>
  );
}
