import React, { useEffect } from 'react';

import {
  pushGameConfigObjectiveForm,
  initGameConfigObjective
} from '@infrastructure/store/createDirectConfig/createDirectConfigActions';
import { GameConfigForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/GameConfigForm';
import { GameConfigSummary } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/components/gameConfigSummary/GameConfigSummary';
import { useForm } from '@ui/hooks/form';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { GameConfigObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';

type Props = {
  isCompleted?: boolean;
};

export function GameConfigFormContainer({ isCompleted }: Props) {
  const dispatch = useDispatch();
  const { gameConfigObjective } = useSelector(createDirectConfigSelectors.getForm);

  const {
    register,
    control,
    watch,
    handleSubmit,
    setFocus,
    formState: { errors }
  } = useForm<GameConfigObjectiveForm>({
    schema: GameConfigObjectiveForm,
    defaultValues: gameConfigObjective
  });

  const gameConfigParams = useSelector(createDirectConfigSelectors.getGameConfigParams);

  useEffect(() => {
    dispatch(initGameConfigObjective());
  }, [dispatch]);

  const handleNext = handleSubmit((form) => {
    dispatch(pushGameConfigObjectiveForm(form));
  });

  if (isCompleted) {
    return <GameConfigSummary />;
  }

  return (
    <GameConfigForm
      handleSubmit={handleNext}
      control={control}
      register={register}
      watch={watch}
      errors={errors}
      setFocus={setFocus}
      gameConfigParams={gameConfigParams}
    />
  );
}
