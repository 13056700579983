import React from 'react';
import { DateUtils } from '@app/utils/DateUtils';

import styles from './DateLabel.module.scss';

type Props = {
  date: Date | string | number | null;
  fallbackText?: string;
};

export function DateLabel({ date, fallbackText }: Props) {
  if (!date || !DateUtils.isValidDate(date)) {
    return <time className={styles.main}>{fallbackText || 'Invalid Date'}</time>;
  }

  const isoDate = DateUtils.formatToDateISODate(date);
  const formattedDate = DateUtils.formatDate(date, DateUtils.getDatePattern());
  const [day, month, year] = formattedDate.split('/');

  return (
    <time dateTime={isoDate} className={styles.main}>
      {day}
      <span>/</span>
      {month}
      <span>/</span>
      {year}
    </time>
  );
}
