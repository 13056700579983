import React from 'react';
import { UseFormSetFocus, UseFormWatch } from 'react-hook-form';
import { FormComponent } from '@ui/hooks/form';

import { GLDParamDto } from '@domain/enums/GLDParamDto';
import { InputGroup } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/components/inputGroup/InputGroup';
import { NextButton } from '@components/layout/NextButton/NextButton';
import { GameConfigObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';

type Props = FormComponent<GameConfigObjectiveForm> & {
  watch: UseFormWatch<GameConfigObjectiveForm>;
  setFocus: UseFormSetFocus<GameConfigObjectiveForm>;
  gameConfigParams: GLDParamDto[];
};

export function GameConfigForm({ handleSubmit, register, control, watch, setFocus, gameConfigParams }: Props) {
  return (
    <form onSubmit={handleSubmit}>
      <InputGroup
        register={register}
        control={control}
        watch={watch}
        setFocus={setFocus}
        gameConfigParams={gameConfigParams}
      />

      <NextButton />
    </form>
  );
}
