import { useSelector } from '@ui/hooks/redux';

import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { List } from '@pages/createDirectConfig/notifications/liveConfigurations/list/List';
import { Notification } from '@pages/createExperiment/notifications/Notification';

export function LiveConfigurationsContainer() {
  const configs = useSelector(createDirectConfigSelectors.getLiveConfigs);

  if (!configs.length) {
    return null;
  }

  return (
    <Notification title="Live configurations">
      <List configs={configs} />
    </Notification>
  );
}
