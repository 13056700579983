import React, { useEffect } from 'react';
import { Control, useController, UseFormResetField, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';
import { memoize } from 'proxy-memoize';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { BasicInfoParams } from '@pages/createDirectConfig/basicInfo/types/BasicInfoParams';
import { useSelector } from '@ui/hooks/redux';

import styles from '@pages/createExperiment/basicInfo/BasicInfo.module.scss';

const inputName = 'platform';
const watchName = 'gameName';

type Props = {
  control?: Control<BasicInfoParams>;
  watch: UseFormWatch<BasicInfoParams>;
  setValue: UseFormSetValue<BasicInfoParams>;
  resetField: UseFormResetField<BasicInfoParams>;
};

export function PlatformInput({ control, watch, setValue, resetField }: Props) {
  const gameName = watch(watchName);
  const { field, formState } = useController({ control, name: inputName });
  const options = useSelector(memoize(createExperimentSelectors.getPlatformOptions(gameName)));

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === watchName && type === 'change') {
        resetField('platform');
      }
    });
    return () => subscription.unsubscribe();
  }, [resetField, watch, options, field]);

  useEffect(() => {
    if (options.length === 1 && !field.value) {
      setValue('platform', options[0].value);
    }
  }, [setValue, options, field]);

  return (
    <DropdownInput
      name={inputName}
      labelText="Platform"
      placeholder="Select platform"
      value={field.value}
      options={options}
      className={styles.input}
      disabled={!gameName}
      handleChange={(event) => field.onChange(event.value)}
      error={formState.errors?.[inputName]?.message}
    />
  );
}
