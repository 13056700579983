import React from 'react';

import { AdProfileObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/types/AdProfileObjectiveForm';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';

type Props = { adProfileObjective: AdProfileObjectiveForm; variables: ExperimentVariableDto[] };

export function TRow({ adProfileObjective, variables }: Props) {
  const { params, controlGroup } = adProfileObjective;
  return (
    <tr>
      {params.map((key) => {
        const currentVariable = variables.find(({ name }) => name === key);
        const isBoolean = currentVariable?.type === ExperimentVariableType.BOOLEAN;
        const formattedValue = isBoolean ? (controlGroup[key] === '1' ? 'true' : 'false') : controlGroup[key];

        return <td key={key}>{formattedValue}</td>;
      })}
    </tr>
  );
}
