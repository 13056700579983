import { createReducer } from '@reduxjs/toolkit';

import { fetchDirectConfigs, clearAll } from '@infrastructure/store/directConfigList/directConfigListActions';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';

const listInitial: IDirectConfig[] = [];

export const listReducer = createReducer(listInitial, (qb) => {
  qb.addCase(clearAll, () => {
    return listInitial;
  });
  qb.addCase(fetchDirectConfigs.success, (state, { payload }) => {
    return payload.thisPageItems;
  });
});
