import React from 'react';

import { TableCellType } from '@components/layout/table/types/TableCellType';

type HeaderCellProps = {
  type: string;
  labelText: string;
};

function HeaderCell({ type, labelText }: HeaderCellProps) {
  return <th data-cell={type}>{labelText}</th>;
}

export function DirectConfigHeader() {
  return (
    <tr>
      <HeaderCell type={TableCellType.TITLE} labelText="App Name" />
      <HeaderCell type={TableCellType.TITLE} labelText="Test Name" />
      <HeaderCell type={TableCellType.ICON} labelText="Store" />
      <HeaderCell type={TableCellType.TEXT} labelText="Test Type" />
      <HeaderCell type={TableCellType.LABEL} labelText="Status" />
      <HeaderCell type={TableCellType.DATE} labelText="Dates" />
      <HeaderCell type={TableCellType.LABEL} labelText="Country" />
    </tr>
  );
}
