import React from 'react';

import { ResourceMapper } from '@app/mappers/ResourceMapper';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';
import { DirectConfigState } from '@domain/enums/directConfig/DirectConfigState';
import { ExperimentName } from '@domain/types';
import { DirectConfigStatus } from '@components/layout/directConfigStatus/DirectConfigStatus';
import { ActionsContainer } from '@pages/directConfigDetails/components/actions/ActionsContainer';
import { Image } from '@components/layout/Image';

import styles from '@pages/experimentDetails/components/header/Header.module.scss';

type Props = {
  game: GameApplicationDto;
  state: DirectConfigState;
  name: ExperimentName;
};

// todo [Tech-debt] [CU-1642] generalize component for experiment & direct config details pages
export function Header({ game, name, state }: Props) {
  const iconUrlFormatted = ResourceMapper.mapS3UrlToCDN(game.iconUrl);

  return (
    <header className={styles.wrapper}>
      <div className={styles.header}>
        <Image src={iconUrlFormatted} alt="Game icon" className={styles.icon} />
        <ActionsContainer />
        <div className={styles.expName}>
          <p>{name}</p>
          <DirectConfigStatus status={state} />
        </div>
        <div className={styles.gameName}>{game.gameName}</div>
      </div>
    </header>
  );
}
