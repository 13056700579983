import { useSelector } from '@ui/hooks/redux';

import { List } from '@pages/createExperiment/notifications/overlappedConfigs/list/List';
import { Notification } from '@pages/createExperiment/notifications/Notification';
import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';

export function OverlappedConfigsContainer() {
  const configs = useSelector(createDirectConfigSelectors.getOverlappedConfigs);
  const { basicInfo } = useSelector(createDirectConfigSelectors.getForm);

  if (!configs.length) {
    return null;
  }

  return (
    <Notification title="Overlapping configuration found">
      <List configs={configs} firebaseId={basicInfo.firebaseProjectId} />
    </Notification>
  );
}
