import React from 'react';
import { useSelector } from '@ui/hooks/redux';

import { Header } from '@pages/directConfigDetails/components/header/Header';
import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';

export function HeaderContainer() {
  const directConfig = useSelector(directConfigDetailsSelectors.getDirectConfig);
  const { name, state, game } = directConfig;

  return <Header name={name} state={state} game={game} />;
}
