import React from 'react';

import { DirectConfigDefinitionContainer } from '@pages/directConfigDetails/components/summary/config/runningForm/sections/definition/DirectConfigDefinitionContainer';
import { GenericConfigContainer } from '@pages/directConfigDetails/components/summary/config/runningForm/sections/groups/GenericConfigContainer';
import { ConfigSection } from '@pages/directConfigDetails/components/summary/config/atoms/ConfigSection';

import styles from '../DirectConfigForm.module.scss';

export function RunningFormContainer() {
  return (
    <div className={styles.config}>
      <main>
        <ConfigSection label="Targeting Segment Definition:">
          <DirectConfigDefinitionContainer />
        </ConfigSection>
        <ConfigSection>
          <GenericConfigContainer />
        </ConfigSection>
      </main>
    </div>
  );
}
