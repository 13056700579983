import React from 'react';
import { Control, useController, UseFormRegister } from 'react-hook-form';
import { InputLabel, RadioInput } from 'crazy-ui-next';

import { DirectConfigType, DirectConfigTypeLabels } from '@domain/enums/directConfig/DirectConfigType';
import { BasicInfoParams } from '@pages/createDirectConfig/basicInfo/types/BasicInfoParams';
import { getInputProps } from '@ui/hooks/form';

import styles from '@pages/createExperiment/basicInfo/BasicInfo.module.scss';

const options = [
  { label: DirectConfigTypeLabels[DirectConfigType.AD_PROFILE], value: DirectConfigType.AD_PROFILE },
  { label: DirectConfigTypeLabels[DirectConfigType.GAME_CONFIG], value: DirectConfigType.GAME_CONFIG }
];
const name = 'type';

type Props = {
  control?: Control<BasicInfoParams>;
  register: UseFormRegister<BasicInfoParams>;
};

export function TypeInput({ control, register }: Props) {
  const { field, formState } = useController({ control, name });
  const inputProps = getInputProps<BasicInfoParams>(name, register, formState.errors);

  const inputs = options.map(({ label, value }) => {
    return (
      <li key={`r_${value}`}>
        <RadioInput checked={value === field.value} labelText={label} value={value} {...inputProps} />
      </li>
    );
  });

  return (
    <>
      <InputLabel labelText="Segment targeting" error={inputProps.error} />
      <ul className={styles.radioInputGroup}>{inputs}</ul>
    </>
  );
}
