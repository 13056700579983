import React from 'react';
import { Control, useController, UseFormResetField } from 'react-hook-form';
import cn from 'classnames';

import { useSelector } from '@ui/hooks/redux';
import { RegionMapper } from '@app/mappers/experiment/RegionMapper';
import { InDevFormParams } from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';
import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';
import { SelectInput } from '@components/lib/Select/SelectInput';

import styles from '../TargetingConfig.module.scss';
import { InputLabel } from 'crazy-ui-next';

const inputName = 'liveRegions';

type Props = {
  control: Control<InDevFormParams>;
  resetField: UseFormResetField<InDevFormParams>;
};

export function LiveRegionsInput({ control }: Props) {
  const { field, formState } = useController({ control, name: inputName });

  const defaultRegions = useSelector(directConfigDetailsSelectors.getDefaultRegions);
  const status = useSelector(directConfigDetailsSelectors.getDefaultRegionsStatus);

  const isLoading = status === RequestStage.REQUEST;

  const options = RegionMapper.createOptions(defaultRegions);

  const error = formState.errors?.liveRegions?.message;

  return (
    <li>
      <InputLabel labelText="Live Regions:" className={styles.editorInputLabel} />
      <SelectInput
        labelText=""
        name="liveRegions"
        placeholder="Select regions"
        value={field.value}
        options={options}
        handleChange={field.onChange}
        error={error}
        className={cn(styles.input, styles.dropdownInput)}
        isMulti
        isClearable
        isDisabled={isLoading}
      />
    </li>
  );
}
