import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';
import { memoize } from 'proxy-memoize';
import { isEqual } from 'lodash-es';

import { useForm } from '@ui/hooks/form';
import { useToggle } from '@ui/hooks/useToggle';
import useModal from '@ui/hooks/useModal';
import { usePrevious } from '@ui/hooks/usePrevious';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ExpGroupsConfigParams } from '@domain/models/experimentDetails/ExpGroupsConfigParams';
import { ExperimentType } from '@domain/enums/ExperimentType';

import { THead } from '@pages/experimentDetails/components/summary/config/groups/atoms/THead';
import { TBody } from '@pages/experimentDetails/components/summary/config/groups/atoms/TBody';
import { FormControls } from '@pages/experimentDetails/components/summary/config/atoms/FormControls';

import { useDispatch } from '@ui/hooks/redux';
import { pushStopGroups } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { StopModal } from '@pages/experimentDetails/components/summary/config/groups/atoms/StopModal';

import styles from './GroupsConfig.module.scss';

type Props = {
  objectiveId: number;
  isLoading: boolean;
};

export function GroupsConfig({ objectiveId, isLoading }: Props) {
  const dispatch = useDispatch();

  const { isActive: editorEnabled, handleOpen: openEditor, handleClose: closeEditor } = useToggle(false);

  const { experimentType } = useSelector(experimentDetailsSelectors.getExperiment);
  const selectedObjective = useSelector(experimentDetailsSelectors.getExpObjectiveById(objectiveId));
  const variables = useSelector(memoize(experimentDetailsSelectors.getObjectiveVariables(objectiveId)));
  const form = useSelector(memoize(experimentDetailsSelectors.getGroupsConfigParams));
  const cachedForm = usePrevious(form);
  const isSummaryEditorLoading = useSelector(experimentDetailsSelectors.getIsSummaryEditorLoading);
  const isDau = useSelector(experimentDetailsSelectors.getIsDau);
  const isAB = experimentType === ExperimentType.AB_TESTING;

  const controlsDisabled: { edit: boolean; submit: boolean } = {
    edit: isDau || isSummaryEditorLoading,
    submit: isDau || isSummaryEditorLoading
  };

  const { showModal, handleOpenModal, handleCloseModal } = useModal();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<ExpGroupsConfigParams>({
    schema: ExpGroupsConfigParams,
    defaultValues: form
  });

  useEffect(() => {
    if (!isEqual(form, cachedForm)) {
      reset(form);
    }
  }, [form, cachedForm, reset]);

  const groups = useWatch({ control, name: 'groups' });

  if (!selectedObjective) {
    return null;
  }

  const { cloneControlGroup } = selectedObjective;

  const handleFormSubmit = handleSubmit((params) => {
    dispatch(pushStopGroups.trigger(params));
    closeEditor();
  });

  const checkedGroups = groups.filter(({ checked }) => checked);
  const activeGroupsLeft = groups.filter(({ active, checked }) => active && !checked);

  const minActiveGroups = cloneControlGroup ? 3 : 2;
  const disableSubmit = checkedGroups.length === 0 || activeGroupsLeft.length < minActiveGroups;

  const checkedGroupNames = checkedGroups.map(({ name }) => name);

  const titleText = isAB ? 'Ad Profiles Groups' : 'Game Config Groups';

  return (
    <>
      <div className={styles.tableWrapper}>
        <p className={styles.tableTitle}>{titleText}: </p>
        <table className={styles.table}>
          <THead variables={variables} isAB={isAB} />
          <TBody
            variables={variables}
            control={control}
            errors={errors}
            register={register}
            editorEnabled={editorEnabled}
            isAB={isAB}
          />
        </table>
      </div>
      <FormControls
        submitLabel="Shutdown Groups"
        openEditor={openEditor}
        closeEditor={closeEditor}
        editorEnabled={editorEnabled}
        handleSubmit={handleOpenModal}
        disableSubmit={disableSubmit}
        isLoading={isLoading}
        controlsDisabled={controlsDisabled}
      />
      {showModal && (
        <StopModal
          handleCloseModal={handleCloseModal}
          handleStopGroups={handleFormSubmit}
          groups={checkedGroupNames}
          closeEditor={closeEditor}
        />
      )}
    </>
  );
}
