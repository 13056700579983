import React from 'react';

import { createDirectConfigSelectors } from '@infrastructure/store/createDirectConfig/createDirectConfigSelectors';
import { useSelector } from '@ui/hooks/redux';
import { CONFIG_GROUP_NAME } from '@domain/enums/ConfigGroupName';
import { THead } from './atoms/THead';
import { TBody } from './atoms/TBody';

import styles from './GameConfigSummary.module.scss';

export function GameConfigSummary() {
  const { gameConfigObjective } = useSelector(createDirectConfigSelectors.getForm);

  const { config } = gameConfigObjective;

  const controlGroup = config.find(({ name }) => name === CONFIG_GROUP_NAME.CONTROL);

  return (
    <div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <THead controlGroup={controlGroup} />
          <TBody config={config} />
        </table>
      </div>
    </div>
  );
}
