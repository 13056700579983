import React, { useCallback } from 'react';

import { setGameStatsFilter } from '@infrastructure/store/createExperiment/createExperimentActions';
import { GameStats } from '@pages/createExperiment/targetConfig/components/gameStats/GameStats';
import { GameStatsPlaceholder } from '@pages/createExperiment/targetConfig/components/gameStats/GameStatsPlaceholder';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';

// todo [Tech-debt] [CU-1642] share between experiment & direct config & create form
export function GameStatsContainer() {
  const dispatch = useDispatch();
  const gameStats = useSelector(directConfigDetailsSelectors.getGameStatsByPlatform);
  const filter = useSelector(experimentDetailsSelectors.getGameStatsFilter);

  const handleSetFilter = useCallback(
    (value) => {
      dispatch(setGameStatsFilter(value));
    },
    [dispatch]
  );

  if (!gameStats.length) {
    return <GameStatsPlaceholder />;
  }

  return <GameStats gameStats={gameStats} filter={filter} handleSetFilter={handleSetFilter} />;
}
