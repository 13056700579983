import { AppState } from '@infrastructure/store';
import { DirectConfigFiltersForm } from '@pages/directConfigList/filters/types/DirectConfigFiltersForm';

const getPagination = (state: AppState) => state.directConfigList.pagination;
const getStatus = (state: AppState) => state.directConfigList.status;
const getDirectConfigs = (state: AppState) => state.directConfigList.list;

const getFilters = (state: AppState) => {
  const { _persist, ...filters } = state.directConfigList.filters;
  return new DirectConfigFiltersForm(filters);
};

const getRequestParams = (state: AppState) => {
  const filters = getFilters(state);

  return filters.toRequestParams();
};

export const directConfigListSelectors = {
  getPagination,
  getStatus,
  getDirectConfigs,
  getFilters,
  getRequestParams
};
