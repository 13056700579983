import React, { useEffect } from 'react';
import { Control, useController, UseFormResetField } from 'react-hook-form';

import { RegionMapper } from '@app/mappers/experiment/RegionMapper';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { ExperimentRegionName } from '@domain/enums/ExperimentRegion';
import { SelectInput } from '@components/lib/Select/SelectInput';
import { useSelector } from '@ui/hooks/redux';
import { ArrayUtils } from '@app/utils/ArrayUtils';

import styles from '@pages/createExperiment/targetConfig/TargetingConfig.module.scss';

const inputName = 'regionsForInDev';

type Props = {
  control: Control<TargetConfigParams>;
  resetField: UseFormResetField<TargetConfigParams>;
};

export function InDevRegionsInput({ control, resetField }: Props) {
  const { field, formState } = useController({ control, name: inputName });

  const { defaultRegions } = useSelector(createExperimentSelectors.getRegions);

  const options = RegionMapper.createOptions(defaultRegions, [ExperimentRegionName.US]);

  useEffect(() => {
    if (defaultRegions.length) {
      const regions = RegionMapper.getRegionsByNames(
        defaultRegions,
        field.value.map((option) => option.value)
      );
      const defaultValue = RegionMapper.createOptions(regions);
      const shouldReset = !ArrayUtils.isEqualObjects(field.value, defaultValue);

      shouldReset && resetField(inputName, { defaultValue });
    }
  }, [defaultRegions, field, resetField]);

  return (
    <SelectInput
      name="inDevRegions"
      labelText="In dev regions"
      value={field.value}
      options={options}
      handleChange={field.onChange}
      error={formState.errors?.regions?.message}
      className={styles.input}
      placeholder="Select region"
      isMulti
      isClearable
    />
  );
}
