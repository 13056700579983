import { ArrayNotEmpty, IsArray, IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { UserPropertiesParams } from '@domain/models/createExperiment/userProperties/UserPropertiesParams';
import { VersionInput } from '@domain/models/VersionInput';
import { RegionOption } from '@domain/models/createExperiment/TargetConfigParams';
import { VersionOperator } from '@domain/enums/VersionOperator';
import { ControlGroupForm } from '@pages/createDirectConfig/objectiveConfig/forms/adProfile/types/AdProfileObjectiveForm';
import { DirectConfigType } from '@domain/enums/directConfig/DirectConfigType';
import { merge } from 'lodash-es';

export class InDevConfigFormInput {
  constructor(key: string, value: string | null) {
    this.key = key;
    this.value = value;
  }

  key: string;
  value: string | null;

  public setKey(key: string): InDevConfigFormInput {
    this.key = key;
    return this;
  }

  public setValue(value: string | null): InDevConfigFormInput {
    this.value = value;
    return this;
  }
}

export class InDevFormParams {
  constructor(directConfig?: IDirectConfig) {
    if (!directConfig) {
      return this;
    }

    this.name = directConfig.name;
    this.importedSegments = directConfig.importedSegments;
    this.liveRegions = directConfig.liveRegions.map(({ name }) => ({ value: name, label: name }));
    this.inDevRegions = directConfig.inDevRegions.map(({ name }) => ({ value: name, label: name }));
    this.appVersions = directConfig.appVersions.map((version) => ({ value: version }));
    this.appliedOperator = directConfig.appliedOperator;

    const initUserProperties: UserPropertiesParams = { inputs: [] };

    this.userProperties = directConfig.userProperties
      ? directConfig.userProperties.reduce((acc, property) => {
          acc.inputs.push({
            operatorId: property.operatorId.toString(),
            value: property.value,
            userPropertyId: property.userPropertyId.toString(),
            userPropertyDisplayName: property.userPropertyDisplayName,
            operatorDisplayName: property.operatorDisplayName
          });

          return acc;
        }, initUserProperties)
      : initUserProperties;

    const isAdProfile = directConfig.type === DirectConfigType.AD_PROFILE;

    if (isAdProfile) {
      this.params = Object.keys(directConfig.genericConfigList[0].entry).map((key) => key);
    } else {
      this.config = Object.entries(directConfig.genericConfigList[0].entry).map(
        ([key, value]) => new InDevConfigFormInput(key, value.configValue.value as string | null)
      );
    }
  }

  @IsString()
  @IsNotEmpty()
  public name: string;

  @IsString()
  public importedSegments: string;

  @IsArray()
  @ArrayNotEmpty()
  liveRegions: RegionOption[];

  @IsArray()
  @ArrayNotEmpty()
  inDevRegions: RegionOption[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => VersionInput)
  appVersions: VersionInput[];

  appliedOperator: VersionOperator;

  userProperties: UserPropertiesParams;

  @IsArray()
  @ArrayNotEmpty()
  config: InDevConfigFormInput[];

  @IsArray()
  @ArrayNotEmpty()
  params: string[] = [];

  controlGroup: ControlGroupForm;

  public mergeControlGroup(controlGroup: ControlGroupForm) {
    this.controlGroup = merge(controlGroup, this.controlGroup);
    return this;
  }
}
