import React from 'react';

type Props = {
  name: string;
};

export function ConfigName({ name }: Props) {
  return (
    <td>
      <div>
        <span>{name}</span>
      </div>
    </td>
  );
}
