import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { isEmpty } from 'lodash-es';

import { RouteParams } from '@infrastructure/routing';
import { RequestStage } from '@infrastructure/store/types/actions';
import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';
import {
  initDirectConfigDetails,
  clearAll
} from '@infrastructure/store/directConfigDetails/directConfigDetailsActions';
import { PageLoader } from '@components/layout/Loader';
import { NotFound } from '@pages/common/NotFound';
import { DirectConfigDetails } from '@pages/directConfigDetails/DirectConfigDetails';

export function DirectConfigDetailsContainer() {
  const dispatch = useDispatch();
  const { directConfigId } = useParams<RouteParams.GetDirectConfig>();
  const id = Number(directConfigId);

  const status = useSelector(directConfigDetailsSelectors.getDirectConfigStatus);

  const directConfig = useSelector(directConfigDetailsSelectors.getDirectConfig);

  useEffect(() => {
    dispatch(initDirectConfigDetails.trigger(id));

    return () => {
      dispatch(clearAll());
    };
  }, [dispatch, id]);

  if (status !== RequestStage.SUCCESS) {
    return <PageLoader />;
  }

  if (isEmpty(directConfig)) {
    return <NotFound />;
  }

  return <DirectConfigDetails />;
}
