// We should import each event subscribers to include the code to bundle
import { Container } from 'typedi';
import { AuthSaga } from '@infrastructure/sagas/AuthSaga';
import { ExperimentListSaga } from '@infrastructure/sagas/ExperimentListSaga';
import { UserManagementSaga } from '@infrastructure/sagas/UserManagementSaga';
import { ConfigSaga } from '@infrastructure/sagas/ConfigSaga';
import { AppliedConfigSaga } from '@infrastructure/sagas/AppliedConfigSaga';
import { ExperimentDetailsSaga } from '@infrastructure/sagas/ExperimentDetailsSaga';
import { DirectConfigDetailsSaga } from '@infrastructure/sagas/DirectConfigDetailsSaga';
import { DirectConfigListSaga } from '@infrastructure/sagas/DirectConfigListSaga';
import { CreateExperimentSaga } from '@infrastructure/sagas/CreateExperimentSaga';
import { CreateDirectConfigSaga } from '@infrastructure/sagas/CreateDirectConfigSaga';

Container.get(AuthSaga);
Container.get(ConfigSaga);
Container.get(ExperimentListSaga);
Container.get(DirectConfigListSaga);
Container.get(UserManagementSaga);
Container.get(AppliedConfigSaga);
Container.get(ExperimentDetailsSaga);
Container.get(DirectConfigDetailsSaga);
Container.get(CreateExperimentSaga);
Container.get(CreateDirectConfigSaga);
