import React, { useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from '@ui/hooks/redux';

import { clearAll, pushExperimentClone } from '@infrastructure/store/createExperiment/createExperimentActions';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';

import { CreateExperiment } from '@pages/createExperiment/CreateExperiment';
import { OverlappedConfigsContainer } from '@pages/createExperiment/notifications/overlappedConfigs/OverlappedConfigsContainer';
import { LiveConfigurationsContainer } from '@pages/createExperiment/notifications/liveConfigurations/LiveConfigurationsContainer';
import CheckPermission from '@components/layout/CheckPermissions';
import { PageLoader } from '@components/layout/Loader';

import styles from '@pages/createExperiment/CreateExperiment.module.scss';

export function CreateExperimentContainer() {
  const dispatch = useDispatch();
  const cloneStatus = useSelector(createExperimentSelectors.cloneStatus);

  useEffect(() => {
    dispatch(pushExperimentClone.trigger());
    return () => {
      dispatch(clearAll());
    };
  }, [dispatch]);

  if (cloneStatus !== RequestStage.SUCCESS) {
    return <PageLoader />;
  }

  // todo extend RouteByRole and move this logic
  return (
    <CheckPermission admin analyst qa>
      <main className={cn(styles.main, 'container')}>
        <CreateExperiment />
        <section className={styles.notifications}>
          <OverlappedConfigsContainer />
          <LiveConfigurationsContainer />
        </section>
      </main>
    </CheckPermission>
  );
}
