import React from 'react';

import { HeaderContainer } from '@pages/directConfigDetails/components/header/HeaderContainer';
import { SummaryContainer } from '@pages/directConfigDetails/components/summary/SummaryContainer';

import styles from '@pages/directConfigDetails/DirectConfigDetails.module.scss';

export function DirectConfigDetails() {
  return (
    <>
      <HeaderContainer />
      <section className={styles.section}>
        <SummaryContainer />
      </section>
    </>
  );
}
