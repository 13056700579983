import React from 'react';
import { memoize } from 'proxy-memoize';
import { useSelector } from '@ui/hooks/redux';

import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';
import { RunningFormParams } from '@pages/directConfigDetails/components/summary/config/runningForm/types/RunningFormParams';

import styles from '../../../../../../DirectConfigDetails.module.scss';

export function DirectConfigDefinitionSummary() {
  const directConfig = useSelector(memoize(directConfigDetailsSelectors.getDirectConfig));
  const params = new RunningFormParams(directConfig);
  const items = params.getSummary().map(({ title, value }) => (
    <li key={title}>
      {title}:<span>{value}</span>
    </li>
  ));

  return (
    <div>
      <ul className={styles.summaryList}>{items}</ul>
    </div>
  );
}
