import React, { ReactNode } from 'react';
import { DropdownOption, SplitButton } from 'crazy-ui-next';

import styles from '@pages/experimentDetails/components/header/Header.module.scss';

export type SplitButtonOption = DropdownOption & {
  icon?: ReactNode;
};

type Props = {
  handleClick: (e: React.MouseEvent) => void;
  options: SplitButtonOption[];
  disabled: boolean;
};

// todo [Tech-debt] [CU-1642] share between experiment & direct config
export function Actions({ handleClick, options, disabled }: Props) {
  return (
    <SplitButton
      options={options}
      value="default"
      name="exp-preview-actions"
      onOptionClick={handleClick}
      className={styles.actions}
      disabled={disabled}
    />
  );
}
